import { defineNuxtPlugin, type NuxtApp } from 'nuxt/app'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeDe from 'dayjs/locale/de'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(utc)
dayjs.extend(timezone) // more details to timezones https://stackoverflow.com/tags/timezone/info
dayjs.locale(localeDe)
dayjs.extend(relativeTime)
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrAfter)

dayjs.tz.setDefault('Europe/Berlin') // set default timezone

const MAX_HEALTHY_TIMEOUT = 30

let dateFunctions: {
  getSupportedTimezones: () => string[]
  getFormattedTimestamp: (isoTimestamp: string) => string
  getFormattedTimestampWithHour: (isoTimestamp: string) => string
  getFormattedTimestampWithSeconds: (isoTimestamp: string) => string
  getFormattedTimestampAsDayAndMonth: (isoTimestamp: string) => string
  getISOTimestampFromGermanDate: (dateString: string, timestampType?: 'startOfDay' | 'endOfDay') => string
  setDayjsTimezone: (timezone: string) => void,
  calculateStatus: (status: any) => any,
  dayjs: typeof dayjs
}

export default defineNuxtPlugin(() => {
  const nuxtApp: NuxtApp = useNuxtApp()

  // from https://github.com/formatjs/date-time-format-timezone/blob/master/tasks/gen-package.js#L51
  const getSupportedTimezones = (): string[] => {
    return [
      'Africa/Abidjan',
      'Africa/Accra',
      'Africa/Algiers',
      'Africa/Bissau',
      'Africa/Cairo',
      'Africa/Casablanca',
      'Africa/Ceuta',
      'Africa/El_Aaiun',
      'Africa/Johannesburg',
      'Africa/Khartoum',
      'Africa/Lagos',
      'Africa/Maputo',
      'Africa/Monrovia',
      'Africa/Nairobi',
      'Africa/Ndjamena',
      'Africa/Tripoli',
      'Africa/Tunis',
      'Africa/Windhoek',
      'America/Anchorage',
      'America/Araguaina',
      'America/Asuncion',
      'America/Bahia',
      'America/Bahia_Banderas',
      'America/Barbados',
      'America/Belem',
      'America/Belize',
      'America/Bogota',
      'America/Boise',
      'America/Campo_Grande',
      'America/Cancun',
      'America/Caracas',
      'America/Cayenne',
      'America/Cayman',
      'America/Chicago',
      'America/Chihuahua',
      'America/Costa_Rica',
      'America/Cuiaba',
      'America/Curacao',
      'America/Dawson_Creek',
      'America/Denver',
      'America/Detroit',
      'America/Edmonton',
      'America/Eirunepe',
      'America/El_Salvador',
      'America/Fortaleza',
      'America/Glace_Bay',
      'America/Godthab',
      'America/Guatemala',
      'America/Guayaquil',
      'America/Guyana',
      'America/Halifax',
      'America/Havana',
      'America/Hermosillo',
      'America/Jamaica',
      'America/Juneau',
      'America/La_Paz',
      'America/Lima',
      'America/Los_Angeles',
      'America/Maceio',
      'America/Managua',
      'America/Manaus',
      'America/Martinique',
      'America/Matamoros',
      'America/Mazatlan',
      'America/Merida',
      'America/Mexico_City',
      'America/Moncton',
      'America/Monterrey',
      'America/Montevideo',
      'America/Nassau',
      'America/New_York',
      'America/Ojinaga',
      'America/Panama',
      'America/Paramaribo',
      'America/Phoenix',
      'America/Porto_Velho',
      'America/Port-au-Prince',
      'America/Port_of_Spain',
      'America/Puerto_Rico',
      'America/Recife',
      'America/Regina',
      'America/Rio_Branco',
      'America/Santarem',
      'America/Santa_Isabel',
      'America/Santiago',
      'America/Santo_Domingo',
      'America/Sao_Paulo',
      'America/St_Johns',
      'America/Swift_Current',
      'America/Tegucigalpa',
      'America/Thunder_Bay',
      'America/Tijuana',
      'America/Toronto',
      'America/Vancouver',
      'America/Whitehorse',
      'America/Winnipeg',
      'America/Yellowknife',
      'Asia/Almaty',
      'Asia/Amman',
      'Asia/Anadyr',
      'Asia/Aqtau',
      'Asia/Aqtobe',
      'Asia/Ashgabat',
      'Asia/Baghdad',
      'Asia/Baku',
      'Asia/Bangkok',
      'Asia/Beirut',
      'Asia/Bishkek',
      'Asia/Brunei',
      'Asia/Chita',
      'Asia/Choibalsan',
      'Asia/Colombo',
      'Asia/Damascus',
      'Asia/Dhaka',
      'Asia/Dili',
      'Asia/Dubai',
      'Asia/Dushanbe',
      'Asia/Gaza',
      'Asia/Hebron',
      'Asia/Hong_Kong',
      'Asia/Hovd',
      'Asia/Ho_Chi_Minh',
      'Asia/Irkutsk',
      'Asia/Jakarta',
      'Asia/Jayapura',
      'Asia/Jerusalem',
      'Asia/Kabul',
      'Asia/Kamchatka',
      'Asia/Karachi',
      'Asia/Kathmandu',
      'Asia/Kolkata',
      'Asia/Krasnoyarsk',
      'Asia/Kuala_Lumpur',
      'Asia/Kuching',
      'Asia/Macau',
      'Asia/Magadan',
      'Asia/Makassar',
      'Asia/Manila',
      'Asia/Nicosia',
      'Asia/Novokuznetsk',
      'Asia/Novosibirsk',
      'Asia/Omsk',
      'Asia/Oral',
      'Asia/Pontianak',
      'Asia/Pyongyang',
      'Asia/Qatar',
      'Asia/Qyzylorda',
      'Asia/Rangoon',
      'Asia/Riyadh',
      'Asia/Sakhalin',
      'Asia/Samarkand',
      'Asia/Seoul',
      'Asia/Shanghai',
      'Asia/Singapore',
      'Asia/Taipei',
      'Asia/Tashkent',
      'Asia/Tbilisi',
      'Asia/Tehran',
      'Asia/Thimphu',
      'Asia/Tokyo',
      'Asia/Tomsk',
      'Asia/Ulaanbaatar',
      'Asia/Urumqi',
      'Asia/Vladivostok',
      'Asia/Yakutsk',
      'Asia/Yekaterinburg',
      'Asia/Yerevan',
      'Atlantic/Azores',
      'Atlantic/Bermuda',
      'Atlantic/Canary',
      'Atlantic/Cape_Verde',
      'Atlantic/Faroe',
      'Atlantic/Madeira',
      'Atlantic/Reykjavik',
      'Australia/Adelaide',
      'Australia/Brisbane',
      'Australia/Broken_Hill',
      'Australia/Darwin',
      'Australia/Hobart',
      'Australia/Melbourne',
      'Australia/Perth',
      'Australia/Sydney',
      'Europe/Amsterdam',
      'Europe/Andorra',
      'Europe/Athens',
      'Europe/Belgrade',
      'Europe/Berlin',
      'Europe/Brussels',
      'Europe/Bucharest',
      'Europe/Budapest',
      'Europe/Chisinau',
      'Europe/Copenhagen',
      'Europe/Dublin',
      'Europe/Gibraltar',
      'Europe/Helsinki',
      'Europe/Istanbul',
      'Europe/Kaliningrad',
      'Europe/Kiev',
      'Europe/Kirov',
      'Europe/Lisbon',
      'Europe/London',
      'Europe/Luxembourg',
      'Europe/Madrid',
      'Europe/Malta',
      'Europe/Minsk',
      'Europe/Monaco',
      'Europe/Moscow',
      'Europe/Oslo',
      'Europe/Paris',
      'Europe/Prague',
      'Europe/Riga',
      'Europe/Rome',
      'Europe/Samara',
      'Europe/Simferopol',
      'Europe/Sofia',
      'Europe/Stockholm',
      'Europe/Tallinn',
      'Europe/Tirane',
      'Europe/Uzhgorod',
      'Europe/Vienna',
      'Europe/Vilnius',
      'Europe/Volgograd',
      'Europe/Warsaw',
      'Europe/Zaporozhye',
      'Europe/Zurich',
      'Indian/Mahe',
      'Indian/Maldives',
      'Indian/Mauritius',
      'Indian/Reunion',
      'Pacific/Apia',
      'Pacific/Auckland',
      'Pacific/Bougainville',
      'Pacific/Chuuk',
      'Pacific/Efate',
      'Pacific/Fiji',
      'Pacific/Galapagos',
      'Pacific/Guadalcanal',
      'Pacific/Guam',
      'Pacific/Honolulu',
      'Pacific/Kwajalein',
      'Pacific/Majuro',
      'Pacific/Norfolk',
      'Pacific/Noumea',
      'Pacific/Palau',
      'Pacific/Pohnpei',
      'Pacific/Port_Moresby',
      'Pacific/Rarotonga',
      'Pacific/Tahiti',
      'Pacific/Tarawa',
      'Pacific/Tongatapu',
      'Pacific/Wake'
    ]
  }

  const getFormattedTimestampWithHour = (isoTimestamp: string): string => {
    let timeString

    if (!isoTimestamp) {
      nuxtApp.$logger.warn('No timestamp provided (getFormattedTimestampWithHour)')
      return '-'
    }

    if (isoTimestamp.startsWith('1601')) {
      // do not add timezone difference for 1601 dates
      timeString = dayjs(isoTimestamp).format('DD.MM.YYYY, HH:mm')
    } else {
      timeString = dayjs.utc(isoTimestamp).tz().format('DD.MM.YYYY, HH:mm')
    }
    const isValidDate = dayjs.utc(isoTimestamp).tz().isValid()

    if (isValidDate) {
      return timeString + ' Uhr'
    } else {
      // return unmodified string in case of date error
      return isoTimestamp
    }
  }

  const getFormattedTimestampAsDayAndMonth = (isoTimestamp: string): string => {
    let dateString

    if (!isoTimestamp) {
      nuxtApp.$logger.warn('No timestamp provided (getFormattedTimestampAsDayAndMonth)')
      return '-'
    }

    if (isoTimestamp.startsWith('1601')) {
      // do not add timezone difference for 1601 dates
      dateString = dayjs(isoTimestamp).format('DD.MM.')
    } else {
      dateString = dayjs.utc(isoTimestamp).tz().format('DD.MM.')
    }

    const isValidDate = dayjs.utc(isoTimestamp).tz().isValid()

    if (isValidDate) {
      return dateString
    } else {
      // return unmodified string in case of date error
      return isoTimestamp
    }
  }

  const getFormattedTimestamp = (isoTimestamp: string): string => {
    let dateString

    if (!isoTimestamp) {
      nuxtApp.$logger.warn('No timestamp provided (getFormattedTimestamp)')
      return '-'
    }

    if (isoTimestamp.startsWith('1601')) {
      // do not add timezone difference for 1601 dates
      dateString = dayjs(isoTimestamp).format('DD.MM.YYYY')
    } else {
      dateString = dayjs.utc(isoTimestamp).tz().format('DD.MM.YYYY')
    }

    const isValidDate = dayjs.utc(isoTimestamp).tz().isValid()

    if (isValidDate) {
      return dateString
    } else {
      // return unmodified string in case of date error
      return isoTimestamp
    }
  }

  const getFormattedTimestampWithSeconds = (isoTimestamp: string): string => {
    let timeString

    if (!isoTimestamp) {
      nuxtApp.$logger.warn('No timestamp provided (getFormattedTimestampWithSeconds)')
      return '-'
    }

    if (isoTimestamp.startsWith('1601')) {
      // do not add timezone difference for 1601 dates
      timeString = dayjs(isoTimestamp).format('DD.MM.YYYY, HH:mm:ss')
    } else {
      timeString = dayjs.utc(isoTimestamp).tz().format('DD.MM.YYYY, HH:mm:ss')
    }

    const isValidDate = dayjs.utc(isoTimestamp).tz().isValid()

    if (isValidDate) {
      return timeString
    } else {
      // return unmodified string in case of date error
      return isoTimestamp
    }
  }

  const getISOTimestampFromGermanDate = (dateString: string, timestampType?: 'startOfDay' | 'endOfDay'): string => {
    let timestamp

    if (timestampType === 'startOfDay') {
      timestamp = dayjs.utc(dateString, 'DD.MM.YYYY').tz().format(
        'YYYY-MM-DDT00:00:00.00000'
      )
    } else if (timestampType === 'endOfDay') {
      timestamp = dayjs.utc(dateString, 'DD.MM.YYYY').tz().format(
        'YYYY-MM-DDT23:59:59.99999'
      )
    } else {
      timestamp = dayjs.utc(dateString, 'DD.MM.YYYY').tz().format(
        'YYYY-MM-DDTHH:mm:ss.SSS00'
      )
    }

    return timestamp === 'Invalid date'
      ? dayjs.utc(dateString, 'DD.MM.YYYY').tz().format('YYYY-MM-DDTHH:mm:ss.SSS00')
      : timestamp
  }

  const setDayjsTimezone = (timezone: string): void => {
    dayjs.tz.setDefault(timezone)
    nuxtApp.$logger.info(`Set dayjs timezone to ${timezone}`)
  }

  const calculateStatus = (status: any) => {
    try {
      const now = dayjs.utc() // now in utc
      const replicationStatus = getHealth(now, status.replicationTime) // if last change is too long ago, the collector probably has issues with receiving replication data
      const adDataStatus = getHealth(now, status.lastTimeADData) // if last change is too long ago, the collector probably has issues with receiving data from ad
      const collectorStatus = getHealth(now, status.lastTimeCollector) // if last change is too long ago, collector is not running or not connected to database

      const state =
        replicationStatus === 'healthy' &&
        adDataStatus === 'healthy' &&
        collectorStatus === 'healthy'
          ? 'healthy'
          : 'unhealthy'

      return {
        replication: replicationStatus,
        ad: adDataStatus,
        collector: collectorStatus,
        color: getStatusColor(state),
        state
      }
    } catch (e) {
      throw new Error(`Error calculating collector status ${e}`)
    }
  }

  dateFunctions = {
    getSupportedTimezones,
    getFormattedTimestampWithHour,
    getFormattedTimestampWithSeconds,
    getFormattedTimestampAsDayAndMonth,
    getISOTimestampFromGermanDate,
    setDayjsTimezone,
    calculateStatus,
    getFormattedTimestamp,
    dayjs
  }

  return {
    provide: {
      dayjs: dateFunctions
    }
  }
})

function getHealth (now: any, lastChangeDateString: string) {
  const lastChangeDate = dayjs.utc(lastChangeDateString)
  const timespan = now.diff(lastChangeDate, 'minute')

  if (timespan > MAX_HEALTHY_TIMEOUT || lastChangeDateString === null) {
    return 'unhealthy'
  }

  return 'healthy'
}

function getStatusColor (state: string): string {
  if (state === 'unhealthy') {
    return 'bg-red text-common-black'
  }
  return 'bg-common-green text-common-black'
}

// @ts-ignore
export const dateFunc = dateFunctions
