/* tslint:disable */
/* eslint-disable */
/**
 * SEC AUDITOR API
 * Mehr unter https://sec-auditor.com
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * Successful response
 * @export
 * @interface ActivateCollectorLicense201Response
 */
export interface ActivateCollectorLicense201Response {
    /**
     * 
     * @type {string}
     * @memberof ActivateCollectorLicense201Response
     */
    'message': string;
}
/**
 * Authorization error, could not verify JWT
 * @export
 * @interface ActivateCollectorLicense401Response
 */
export interface ActivateCollectorLicense401Response {
    /**
     * 
     * @type {string}
     * @memberof ActivateCollectorLicense401Response
     */
    'error': string;
    /**
     * 
     * @type {string}
     * @memberof ActivateCollectorLicense401Response
     */
    'message': string;
}
/**
 * No permission on requested ressource
 * @export
 * @interface ActivateCollectorLicense403Response
 */
export interface ActivateCollectorLicense403Response {
    /**
     * 
     * @type {string}
     * @memberof ActivateCollectorLicense403Response
     */
    'error': string;
    /**
     * 
     * @type {string}
     * @memberof ActivateCollectorLicense403Response
     */
    'message'?: string;
}
/**
 * An general error occurred during the request
 * @export
 * @interface ActivateCollectorLicense500Response
 */
export interface ActivateCollectorLicense500Response {
    /**
     * 
     * @type {string}
     * @memberof ActivateCollectorLicense500Response
     */
    'error': string;
    /**
     * 
     * @type {string}
     * @memberof ActivateCollectorLicense500Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ActivateCollectorLicenseRequest
 */
export interface ActivateCollectorLicenseRequest {
    /**
     * 
     * @type {string}
     * @memberof ActivateCollectorLicenseRequest
     */
    'machineCode'?: string;
}
/**
 * 
 * @export
 * @interface AddClientTeamMember200ResponseInner
 */
export interface AddClientTeamMember200ResponseInner {
    /**
     * 
     * @type {object}
     * @memberof AddClientTeamMember200ResponseInner
     */
    'user'?: object;
}
/**
 * 
 * @export
 * @interface AddClientTeamMemberRequest
 */
export interface AddClientTeamMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof AddClientTeamMemberRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddClientTeamMemberRequest
     */
    'firstname'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddClientTeamMemberRequest
     */
    'lastname'?: string;
}
/**
 * Added user object
 * @export
 * @interface AddServiceproviderTeamMember201Response
 */
export interface AddServiceproviderTeamMember201Response {
    /**
     * 
     * @type {string}
     * @memberof AddServiceproviderTeamMember201Response
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface AddServiceproviderTeamMemberRequest
 */
export interface AddServiceproviderTeamMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof AddServiceproviderTeamMemberRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AddServiceproviderTeamMemberRequest
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof AddServiceproviderTeamMemberRequest
     */
    'lastname': string;
}
/**
 * update infos
 * @export
 * @interface CheckUpdate200Response
 */
export interface CheckUpdate200Response {
    /**
     * 
     * @type {string}
     * @memberof CheckUpdate200Response
     */
    'status'?: CheckUpdate200ResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CheckUpdate200Response
     */
    'newestVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckUpdate200Response
     */
    'path'?: string;
}

export const CheckUpdate200ResponseStatusEnum = {
    Ok: 'ok',
    UpdateAvailable: 'update_available'
} as const;

export type CheckUpdate200ResponseStatusEnum = typeof CheckUpdate200ResponseStatusEnum[keyof typeof CheckUpdate200ResponseStatusEnum];

/**
 * Successful response
 * @export
 * @interface CreateClientAsAdmin200Response
 */
export interface CreateClientAsAdmin200Response {
    /**
     * 
     * @type {string}
     * @memberof CreateClientAsAdmin200Response
     */
    'message': string;
}
/**
 * Successful response
 * @export
 * @interface CreateClientAsAdmin201Response
 */
export interface CreateClientAsAdmin201Response {
    /**
     * 
     * @type {string}
     * @memberof CreateClientAsAdmin201Response
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientAsAdmin201Response
     */
    'uuid'?: string;
}
/**
 * Missing required fields in request
 * @export
 * @interface CreateClientAsAdmin400Response
 */
export interface CreateClientAsAdmin400Response {
    /**
     * 
     * @type {string}
     * @memberof CreateClientAsAdmin400Response
     */
    'error': string;
}
/**
 * Authorization error
 * @export
 * @interface CreateClientAsAdmin401Response
 */
export interface CreateClientAsAdmin401Response {
    /**
     * 
     * @type {string}
     * @memberof CreateClientAsAdmin401Response
     */
    'error': string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientAsAdmin401Response
     */
    'message': string;
}
/**
 * An general error occurred during the request
 * @export
 * @interface CreateClientAsAdmin402Response
 */
export interface CreateClientAsAdmin402Response {
    /**
     * 
     * @type {string}
     * @memberof CreateClientAsAdmin402Response
     */
    'error': string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientAsAdmin402Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface CreateClientAsAdminRequest
 */
export interface CreateClientAsAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateClientAsAdminRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientAsAdminRequest
     */
    'location': string;
    /**
     * 
     * @type {number}
     * @memberof CreateClientAsAdminRequest
     */
    'employeeCount': number;
    /**
     * 
     * @type {string}
     * @memberof CreateClientAsAdminRequest
     */
    'mode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientAsAdminRequest
     */
    'serviceproviderUUID'?: string;
}
/**
 * 
 * @export
 * @interface CreateClientRequest
 */
export interface CreateClientRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequest
     */
    'location': string;
    /**
     * 
     * @type {number}
     * @memberof CreateClientRequest
     */
    'employeeCount': number;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequest
     */
    'mode'?: string;
}
/**
 * 
 * @export
 * @interface CreateNewQuickcheckClientRequest
 */
export interface CreateNewQuickcheckClientRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateNewQuickcheckClientRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewQuickcheckClientRequest
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewQuickcheckClientRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewQuickcheckClientRequest
     */
    'serviceproviderUUID'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewQuickcheckClientRequest
     */
    'phone'?: string;
}
/**
 * Successful response
 * @export
 * @interface CreateServiceprovider201Response
 */
export interface CreateServiceprovider201Response {
    /**
     * 
     * @type {string}
     * @memberof CreateServiceprovider201Response
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceprovider201Response
     */
    'uuid'?: string;
}
/**
 * 
 * @export
 * @interface CreateServiceproviderRequest
 */
export interface CreateServiceproviderRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateServiceproviderRequest
     */
    'initialAccountEmail': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceproviderRequest
     */
    'initialAccountFirstname': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceproviderRequest
     */
    'initialAccountLastname': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceproviderRequest
     */
    'companyName': string;
    /**
     * 
     * @type {CreateServiceproviderRequestOther}
     * @memberof CreateServiceproviderRequest
     */
    'other'?: CreateServiceproviderRequestOther;
}
/**
 * 
 * @export
 * @interface CreateServiceproviderRequestOther
 */
export interface CreateServiceproviderRequestOther {
    /**
     * 
     * @type {string}
     * @memberof CreateServiceproviderRequestOther
     */
    'hEmail'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateServiceproviderRequestOther
     */
    't'?: number;
}
/**
 * 
 * @export
 * @interface CustomerGetIoaLog200Response
 */
export interface CustomerGetIoaLog200Response {
    /**
     * 
     * @type {Array<CustomerGetIoaLog200ResponseLogsInner>}
     * @memberof CustomerGetIoaLog200Response
     */
    'logs': Array<CustomerGetIoaLog200ResponseLogsInner>;
    /**
     * 
     * @type {CustomerGetIoeLog200ResponseInfo}
     * @memberof CustomerGetIoaLog200Response
     */
    'info': CustomerGetIoeLog200ResponseInfo;
}
/**
 * 
 * @export
 * @interface CustomerGetIoaLog200ResponseLogsInner
 */
export interface CustomerGetIoaLog200ResponseLogsInner {
    /**
     * 
     * @type {number}
     * @memberof CustomerGetIoaLog200ResponseLogsInner
     */
    'finding_pk'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerGetIoaLog200ResponseLogsInner
     */
    'timestamp'?: string;
    /**
     * 
     * @type {CustomerGetIoaLog200ResponseLogsInnerTblIoaConfig}
     * @memberof CustomerGetIoaLog200ResponseLogsInner
     */
    'tbl_ioa_config'?: CustomerGetIoaLog200ResponseLogsInnerTblIoaConfig;
    /**
     * 
     * @type {CustomerGetIoeLog200ResponseLogsInnerTblComputer}
     * @memberof CustomerGetIoaLog200ResponseLogsInner
     */
    'tbl_computer'?: CustomerGetIoeLog200ResponseLogsInnerTblComputer | null;
    /**
     * 
     * @type {CustomerGetIoeLog200ResponseLogsInnerTblUser}
     * @memberof CustomerGetIoaLog200ResponseLogsInner
     */
    'tbl_user'?: CustomerGetIoeLog200ResponseLogsInnerTblUser | null;
    /**
     * 
     * @type {CustomerGetIoaLog200ResponseLogsInnerTblGroup}
     * @memberof CustomerGetIoaLog200ResponseLogsInner
     */
    'tbl_group'?: CustomerGetIoaLog200ResponseLogsInnerTblGroup | null;
}
/**
 * 
 * @export
 * @interface CustomerGetIoaLog200ResponseLogsInnerTblGroup
 */
export interface CustomerGetIoaLog200ResponseLogsInnerTblGroup {
    /**
     * 
     * @type {CustomerGetIoeLog200ResponseLogsInnerTblGroup}
     * @memberof CustomerGetIoaLog200ResponseLogsInnerTblGroup
     */
    'tbl_group'?: CustomerGetIoeLog200ResponseLogsInnerTblGroup | null;
    /**
     * 
     * @type {CustomerGetIoeLog200ResponseLogsInnerTblUser}
     * @memberof CustomerGetIoaLog200ResponseLogsInnerTblGroup
     */
    'tbl_user'?: CustomerGetIoeLog200ResponseLogsInnerTblUser | null;
    /**
     * 
     * @type {CustomerGetIoeLog200ResponseLogsInnerTblComputer}
     * @memberof CustomerGetIoaLog200ResponseLogsInnerTblGroup
     */
    'tbl_computer'?: CustomerGetIoeLog200ResponseLogsInnerTblComputer | null;
    /**
     * 
     * @type {CustomerGetIoeLog200ResponseLogsInnerTblGroup}
     * @memberof CustomerGetIoaLog200ResponseLogsInnerTblGroup
     */
    'tblGroupByGroupMemberFk'?: CustomerGetIoeLog200ResponseLogsInnerTblGroup | null;
}
/**
 * 
 * @export
 * @interface CustomerGetIoaLog200ResponseLogsInnerTblIoaConfig
 */
export interface CustomerGetIoaLog200ResponseLogsInnerTblIoaConfig {
    /**
     * 
     * @type {string}
     * @memberof CustomerGetIoaLog200ResponseLogsInnerTblIoaConfig
     */
    'ioa_id_pk'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerGetIoaLog200ResponseLogsInnerTblIoaConfig
     */
    'ioa_classification'?: CustomerGetIoaLog200ResponseLogsInnerTblIoaConfigIoaClassificationEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomerGetIoaLog200ResponseLogsInnerTblIoaConfig
     */
    'ioa_classification_category'?: CustomerGetIoaLog200ResponseLogsInnerTblIoaConfigIoaClassificationCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomerGetIoaLog200ResponseLogsInnerTblIoaConfig
     */
    'ioa_title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerGetIoaLog200ResponseLogsInnerTblIoaConfig
     */
    'ioa_summary'?: string;
}

export const CustomerGetIoaLog200ResponseLogsInnerTblIoaConfigIoaClassificationEnum = {
    Malware: 'malware',
    Phishing: 'phishing',
    MaliciousUserActivity: 'malicious-user-activity',
    CompromisedAccount: 'compromised-account',
    AttackOther: 'attack-other',
    SecurityTest: 'security-test',
    Application: 'application',
    ExpectedOther: 'expected-other',
    NotMalicious: 'not-malicious',
    CouldNotBeValidated: 'could-not-be-validated',
    FpOther: 'fp-other'
} as const;

export type CustomerGetIoaLog200ResponseLogsInnerTblIoaConfigIoaClassificationEnum = typeof CustomerGetIoaLog200ResponseLogsInnerTblIoaConfigIoaClassificationEnum[keyof typeof CustomerGetIoaLog200ResponseLogsInnerTblIoaConfigIoaClassificationEnum];
export const CustomerGetIoaLog200ResponseLogsInnerTblIoaConfigIoaClassificationCategoryEnum = {
    Attack: 'attack',
    Expected: 'expected',
    FalsePositive: 'false-positive'
} as const;

export type CustomerGetIoaLog200ResponseLogsInnerTblIoaConfigIoaClassificationCategoryEnum = typeof CustomerGetIoaLog200ResponseLogsInnerTblIoaConfigIoaClassificationCategoryEnum[keyof typeof CustomerGetIoaLog200ResponseLogsInnerTblIoaConfigIoaClassificationCategoryEnum];

/**
 * 
 * @export
 * @interface CustomerGetIoeLog200Response
 */
export interface CustomerGetIoeLog200Response {
    /**
     * 
     * @type {Array<CustomerGetIoeLog200ResponseLogsInner>}
     * @memberof CustomerGetIoeLog200Response
     */
    'logs': Array<CustomerGetIoeLog200ResponseLogsInner>;
    /**
     * 
     * @type {CustomerGetIoeLog200ResponseInfo}
     * @memberof CustomerGetIoeLog200Response
     */
    'info': CustomerGetIoeLog200ResponseInfo;
}
/**
 * 
 * @export
 * @interface CustomerGetIoeLog200ResponseInfo
 */
export interface CustomerGetIoeLog200ResponseInfo {
    /**
     * 
     * @type {number}
     * @memberof CustomerGetIoeLog200ResponseInfo
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerGetIoeLog200ResponseInfo
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerGetIoeLog200ResponseInfo
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerGetIoeLog200ResponseInfo
     */
    'pages'?: number;
}
/**
 * 
 * @export
 * @interface CustomerGetIoeLog200ResponseLogsInner
 */
export interface CustomerGetIoeLog200ResponseLogsInner {
    /**
     * 
     * @type {string}
     * @memberof CustomerGetIoeLog200ResponseLogsInner
     */
    'log_change_type'?: CustomerGetIoeLog200ResponseLogsInnerLogChangeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CustomerGetIoeLog200ResponseLogsInner
     */
    'log_current_security_score'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerGetIoeLog200ResponseLogsInner
     */
    'log_id_pk'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerGetIoeLog200ResponseLogsInner
     */
    'timestamp'?: string;
    /**
     * 
     * @type {CustomerGetIoeLog200ResponseLogsInnerTblIoeConfig}
     * @memberof CustomerGetIoeLog200ResponseLogsInner
     */
    'tbl_ioe_config'?: CustomerGetIoeLog200ResponseLogsInnerTblIoeConfig;
    /**
     * 
     * @type {CustomerGetIoeLog200ResponseLogsInnerTblComputer}
     * @memberof CustomerGetIoeLog200ResponseLogsInner
     */
    'tbl_computer'?: CustomerGetIoeLog200ResponseLogsInnerTblComputer | null;
    /**
     * 
     * @type {CustomerGetIoeLog200ResponseLogsInnerTblUser}
     * @memberof CustomerGetIoeLog200ResponseLogsInner
     */
    'tbl_user'?: CustomerGetIoeLog200ResponseLogsInnerTblUser | null;
    /**
     * 
     * @type {CustomerGetIoeLog200ResponseLogsInnerTblGroup}
     * @memberof CustomerGetIoeLog200ResponseLogsInner
     */
    'tbl_group'?: CustomerGetIoeLog200ResponseLogsInnerTblGroup | null;
    /**
     * 
     * @type {CustomerGetIoeLog200ResponseLogsInnerTblGpo}
     * @memberof CustomerGetIoeLog200ResponseLogsInner
     */
    'tbl_gpo'?: CustomerGetIoeLog200ResponseLogsInnerTblGpo | null;
    /**
     * 
     * @type {CustomerGetIoeLog200ResponseLogsInnerTblOu}
     * @memberof CustomerGetIoeLog200ResponseLogsInner
     */
    'tbl_ou'?: CustomerGetIoeLog200ResponseLogsInnerTblOu | null;
}

export const CustomerGetIoeLog200ResponseLogsInnerLogChangeTypeEnum = {
    New: 'new',
    Regression: 'regression',
    Resolved: 'resolved',
    Disabled: 'disabled'
} as const;

export type CustomerGetIoeLog200ResponseLogsInnerLogChangeTypeEnum = typeof CustomerGetIoeLog200ResponseLogsInnerLogChangeTypeEnum[keyof typeof CustomerGetIoeLog200ResponseLogsInnerLogChangeTypeEnum];

/**
 * 
 * @export
 * @interface CustomerGetIoeLog200ResponseLogsInnerTblComputer
 */
export interface CustomerGetIoeLog200ResponseLogsInnerTblComputer {
    /**
     * 
     * @type {string}
     * @memberof CustomerGetIoeLog200ResponseLogsInnerTblComputer
     */
    'computer_samaccountname'?: string;
}
/**
 * 
 * @export
 * @interface CustomerGetIoeLog200ResponseLogsInnerTblGpo
 */
export interface CustomerGetIoeLog200ResponseLogsInnerTblGpo {
    /**
     * 
     * @type {string}
     * @memberof CustomerGetIoeLog200ResponseLogsInnerTblGpo
     */
    'gpo_displayname'?: string;
}
/**
 * 
 * @export
 * @interface CustomerGetIoeLog200ResponseLogsInnerTblGroup
 */
export interface CustomerGetIoeLog200ResponseLogsInnerTblGroup {
    /**
     * 
     * @type {string}
     * @memberof CustomerGetIoeLog200ResponseLogsInnerTblGroup
     */
    'group_samaccountname'?: string;
}
/**
 * 
 * @export
 * @interface CustomerGetIoeLog200ResponseLogsInnerTblIoeConfig
 */
export interface CustomerGetIoeLog200ResponseLogsInnerTblIoeConfig {
    /**
     * 
     * @type {string}
     * @memberof CustomerGetIoeLog200ResponseLogsInnerTblIoeConfig
     */
    'ioe_id_pk'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerGetIoeLog200ResponseLogsInnerTblIoeConfig
     */
    'criticality'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerGetIoeLog200ResponseLogsInnerTblIoeConfig
     */
    'ioe_title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerGetIoeLog200ResponseLogsInnerTblIoeConfig
     */
    'ioe_summary'?: string;
}
/**
 * 
 * @export
 * @interface CustomerGetIoeLog200ResponseLogsInnerTblOu
 */
export interface CustomerGetIoeLog200ResponseLogsInnerTblOu {
    /**
     * 
     * @type {string}
     * @memberof CustomerGetIoeLog200ResponseLogsInnerTblOu
     */
    'ou_name'?: string;
}
/**
 * 
 * @export
 * @interface CustomerGetIoeLog200ResponseLogsInnerTblUser
 */
export interface CustomerGetIoeLog200ResponseLogsInnerTblUser {
    /**
     * 
     * @type {string}
     * @memberof CustomerGetIoeLog200ResponseLogsInnerTblUser
     */
    'user_samaccountname'?: string;
}
/**
 * Rate limit exceeded
 * @export
 * @interface CustomerGetIoeLog429Response
 */
export interface CustomerGetIoeLog429Response {
    /**
     * 
     * @type {string}
     * @memberof CustomerGetIoeLog429Response
     */
    'error': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerGetIoeLog429Response
     */
    'message': string;
}
/**
 * Successful response
 * @export
 * @interface GetAllIoes200Response
 */
export interface GetAllIoes200Response {
    /**
     * 
     * @type {Array<GetAllIoes200ResponseIoeInner>}
     * @memberof GetAllIoes200Response
     */
    'ioe'?: Array<GetAllIoes200ResponseIoeInner>;
}
/**
 * 
 * @export
 * @interface GetAllIoes200ResponseIoeInner
 */
export interface GetAllIoes200ResponseIoeInner {
    /**
     * 
     * @type {string}
     * @memberof GetAllIoes200ResponseIoeInner
     */
    'ioe_id_pk'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAllIoes200ResponseIoeInner
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAllIoes200ResponseIoeInner
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAllIoes200ResponseIoeInner
     */
    'criticality'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetAllIoes200ResponseIoeInner
     */
    'scoreImpact'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAllIoes200ResponseIoeInner
     */
    'totalFindingsCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAllIoes200ResponseIoeInner
     */
    'totalExceptionsCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetAllIoes200ResponseIoeInner
     */
    'allFindingsHaveException'?: boolean;
    /**
     * 
     * @type {GetAllIoes200ResponseIoeInnerSetting}
     * @memberof GetAllIoes200ResponseIoeInner
     */
    'setting'?: GetAllIoes200ResponseIoeInnerSetting;
    /**
     * 
     * @type {boolean}
     * @memberof GetAllIoes200ResponseIoeInner
     */
    'isActive'?: boolean;
}
/**
 * @type GetAllIoes200ResponseIoeInnerSetting
 * @export
 */
export type GetAllIoes200ResponseIoeInnerSetting = boolean | number | string;

/**
 * Successful response
 * @export
 * @interface GetClientLicense200Response
 */
export interface GetClientLicense200Response {
    /**
     * 
     * @type {string}
     * @memberof GetClientLicense200Response
     */
    'jwt'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetClientLicense200Response
     */
    'employeeCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetClientLicense200Response
     */
    'subscriptionStatus'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetClientLicense200Response
     */
    'paymentDetailsRequired'?: boolean;
}
/**
 * 
 * @export
 * @interface GetClientTeam200ResponseInner
 */
export interface GetClientTeam200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetClientTeam200ResponseInner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetClientTeam200ResponseInner
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetClientTeam200ResponseInner
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetClientTeam200ResponseInner
     */
    'email'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetClientTeam200ResponseInner
     */
    'requiredActions'?: Array<string>;
}
/**
 * Team member
 * @export
 * @interface GetClientTeammember200Response
 */
export interface GetClientTeammember200Response {
    /**
     * 
     * @type {string}
     * @memberof GetClientTeammember200Response
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetClientTeammember200Response
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetClientTeammember200Response
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetClientTeammember200Response
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface GetCollectorHealthStatus200Response
 */
export interface GetCollectorHealthStatus200Response {
    /**
     * 
     * @type {string}
     * @memberof GetCollectorHealthStatus200Response
     */
    'status'?: GetCollectorHealthStatus200ResponseStatusEnum;
}

export const GetCollectorHealthStatus200ResponseStatusEnum = {
    Ok: 'ok',
    Restart: 'restart'
} as const;

export type GetCollectorHealthStatus200ResponseStatusEnum = typeof GetCollectorHealthStatus200ResponseStatusEnum[keyof typeof GetCollectorHealthStatus200ResponseStatusEnum];

/**
 * Successful response
 * @export
 * @interface GetCollectorLicense200Response
 */
export interface GetCollectorLicense200Response {
    /**
     * 
     * @type {string}
     * @memberof GetCollectorLicense200Response
     */
    'subscriptionStatus'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCollectorLicense200Response
     */
    'currentPeriodEnd'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCollectorLicense200Response
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCollectorLicense200Response
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCollectorLicense200Response
     */
    'mode'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCollectorLicense200Response
     */
    'sleepForMs'?: number;
}
/**
 * Authorization error
 * @export
 * @interface GetCollectorLicense401Response
 */
export interface GetCollectorLicense401Response {
    /**
     * 
     * @type {string}
     * @memberof GetCollectorLicense401Response
     */
    'error': string;
    /**
     * 
     * @type {string}
     * @memberof GetCollectorLicense401Response
     */
    'message': string;
}
/**
 * An error occurred during the request
 * @export
 * @interface GetCollectorLicense404Response
 */
export interface GetCollectorLicense404Response {
    /**
     * 
     * @type {string}
     * @memberof GetCollectorLicense404Response
     */
    'error': string;
    /**
     * 
     * @type {string}
     * @memberof GetCollectorLicense404Response
     */
    'message'?: string;
}
/**
 * An error occurred during the request
 * @export
 * @interface GetCollectorLicense500Response
 */
export interface GetCollectorLicense500Response {
    /**
     * 
     * @type {string}
     * @memberof GetCollectorLicense500Response
     */
    'error': string;
    /**
     * 
     * @type {string}
     * @memberof GetCollectorLicense500Response
     */
    'message'?: string;
}
/**
 * Client contact details
 * @export
 * @interface GetContactDetails200Response
 */
export interface GetContactDetails200Response {
    /**
     * 
     * @type {string}
     * @memberof GetContactDetails200Response
     */
    'client_admin_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactDetails200Response
     */
    'client_emergency_contact_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetContactDetails200Response
     */
    'client_emergency_contact_phone'?: string;
}
/**
 * 
 * @export
 * @interface GetExecutables200Response
 */
export interface GetExecutables200Response {
    /**
     * 
     * @type {string}
     * @memberof GetExecutables200Response
     */
    'collector'?: string;
}
/**
 * Successful response
 * @export
 * @interface GetHealth200Response
 */
export interface GetHealth200Response {
    /**
     * 
     * @type {string}
     * @memberof GetHealth200Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHealth200Response
     */
    'version'?: string;
}
/**
 * Error response
 * @export
 * @interface GetHealth500Response
 */
export interface GetHealth500Response {
    /**
     * 
     * @type {string}
     * @memberof GetHealth500Response
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHealth500Response
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetHealth500Response
     */
    'version'?: string;
}
/**
 * Successful response
 * @export
 * @interface GetInstallerLicense200Response
 */
export interface GetInstallerLicense200Response {
    /**
     * 
     * @type {string}
     * @memberof GetInstallerLicense200Response
     */
    'subscriptionStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetInstallerLicense200Response
     */
    'mode'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetInstallerLicense200Response
     */
    'sleepForMs'?: number;
}
/**
 * An error occurred during the request
 * @export
 * @interface GetInstallerLicense500Response
 */
export interface GetInstallerLicense500Response {
    /**
     * 
     * @type {string}
     * @memberof GetInstallerLicense500Response
     */
    'error': string;
    /**
     * 
     * @type {string}
     * @memberof GetInstallerLicense500Response
     */
    'message'?: string;
}
/**
 * Successful response
 * @export
 * @interface GetPasswordChangeLink200Response
 */
export interface GetPasswordChangeLink200Response {
    /**
     * 
     * @type {string}
     * @memberof GetPasswordChangeLink200Response
     */
    'link'?: string;
}
/**
 * Serviceprovider 
 * @export
 * @interface GetServiceprovider200Response
 */
export interface GetServiceprovider200Response {
    /**
     * 
     * @type {string}
     * @memberof GetServiceprovider200Response
     */
    'serviceproviderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetServiceprovider200Response
     */
    'serviceproviderName'?: string;
    /**
     * 
     * @type {GetServiceprovider200ResponseWhitelabel}
     * @memberof GetServiceprovider200Response
     */
    'whitelabel'?: GetServiceprovider200ResponseWhitelabel;
    /**
     * 
     * @type {GetServiceprovider200ResponseQuickcheck}
     * @memberof GetServiceprovider200Response
     */
    'quickcheck'?: GetServiceprovider200ResponseQuickcheck;
    /**
     * 
     * @type {number}
     * @memberof GetServiceprovider200Response
     */
    'clientCount'?: number;
    /**
     * 
     * @type {GetServiceprovider200ResponseLicense}
     * @memberof GetServiceprovider200Response
     */
    'license'?: GetServiceprovider200ResponseLicense;
}
/**
 * 
 * @export
 * @interface GetServiceprovider200ResponseLicense
 */
export interface GetServiceprovider200ResponseLicense {
    /**
     * 
     * @type {string}
     * @memberof GetServiceprovider200ResponseLicense
     */
    'customerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetServiceprovider200ResponseLicense
     */
    'subscriptionStatus'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetServiceprovider200ResponseLicense
     */
    'freeSeats'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetServiceprovider200ResponseLicense
     */
    'paymentDetailsRequired'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetServiceprovider200ResponseLicense
     */
    'subscriptionItemId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetServiceprovider200ResponseLicense
     */
    'productName'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetServiceprovider200ResponseLicense
     */
    'currentPeriodEnd'?: number;
}
/**
 * 
 * @export
 * @interface GetServiceprovider200ResponseQuickcheck
 */
export interface GetServiceprovider200ResponseQuickcheck {
    /**
     * 
     * @type {string}
     * @memberof GetServiceprovider200ResponseQuickcheck
     */
    'origin'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetServiceprovider200ResponseQuickcheck
     */
    'privacy'?: string;
}
/**
 * 
 * @export
 * @interface GetServiceprovider200ResponseWhitelabel
 */
export interface GetServiceprovider200ResponseWhitelabel {
    /**
     * 
     * @type {string}
     * @memberof GetServiceprovider200ResponseWhitelabel
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetServiceprovider200ResponseWhitelabel
     */
    'phone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetServiceprovider200ResponseWhitelabel
     */
    'hasLogo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetServiceprovider200ResponseWhitelabel
     */
    'isActive': boolean;
}
/**
 * Keycloak user object
 * @export
 * @interface GetUser200Response
 */
export interface GetUser200Response {
    /**
     * 
     * @type {string}
     * @memberof GetUser200Response
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof GetUser200Response
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof GetUser200Response
     */
    'email': string;
    /**
     * 
     * @type {GetUser200ResponseNotifications}
     * @memberof GetUser200Response
     */
    'notifications': GetUser200ResponseNotifications;
}
/**
 * 
 * @export
 * @interface GetUser200ResponseNotifications
 */
export interface GetUser200ResponseNotifications {
    /**
     * 
     * @type {boolean}
     * @memberof GetUser200ResponseNotifications
     */
    'alert'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUser200ResponseNotifications
     */
    'quickcheck'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUser200ResponseNotifications
     */
    'info'?: boolean;
}
/**
 * 
 * @export
 * @interface InitialScanRequest
 */
export interface InitialScanRequest {
    /**
     * 
     * @type {string}
     * @memberof InitialScanRequest
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface RemoveClientTeamMemberRequest
 */
export interface RemoveClientTeamMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof RemoveClientTeamMemberRequest
     */
    'userUUID': string;
}
/**
 * 
 * @export
 * @interface SendContactFormRequest
 */
export interface SendContactFormRequest {
    /**
     * 
     * @type {string}
     * @memberof SendContactFormRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SendContactFormRequest
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof SendContactFormRequest
     */
    'lastname': string;
    /**
     * 
     * @type {string}
     * @memberof SendContactFormRequest
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendContactFormRequest
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof SendContactFormRequest
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface SetContactDetailsRequest
 */
export interface SetContactDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof SetContactDetailsRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof SetContactDetailsRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SetContactDetailsRequest
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface SetDatabaseChangeRequest
 */
export interface SetDatabaseChangeRequest {
    /**
     * 
     * @type {string}
     * @memberof SetDatabaseChangeRequest
     */
    'created_at'?: string;
    /**
     * 
     * @type {SetDatabaseChangeRequestDeliveryInfo}
     * @memberof SetDatabaseChangeRequest
     */
    'delivery_info'?: SetDatabaseChangeRequestDeliveryInfo;
    /**
     * 
     * @type {SetDatabaseChangeRequestEvent}
     * @memberof SetDatabaseChangeRequest
     */
    'event'?: SetDatabaseChangeRequestEvent;
    /**
     * 
     * @type {string}
     * @memberof SetDatabaseChangeRequest
     */
    'id'?: string;
    /**
     * 
     * @type {SetDatabaseChangeRequestTable}
     * @memberof SetDatabaseChangeRequest
     */
    'table'?: SetDatabaseChangeRequestTable;
    /**
     * 
     * @type {SetDatabaseChangeRequestTrigger}
     * @memberof SetDatabaseChangeRequest
     */
    'trigger'?: SetDatabaseChangeRequestTrigger;
}
/**
 * 
 * @export
 * @interface SetDatabaseChangeRequestDeliveryInfo
 */
export interface SetDatabaseChangeRequestDeliveryInfo {
    /**
     * 
     * @type {number}
     * @memberof SetDatabaseChangeRequestDeliveryInfo
     */
    'current_retry'?: number;
    /**
     * 
     * @type {number}
     * @memberof SetDatabaseChangeRequestDeliveryInfo
     */
    'max_retries'?: number;
}
/**
 * 
 * @export
 * @interface SetDatabaseChangeRequestEvent
 */
export interface SetDatabaseChangeRequestEvent {
    /**
     * 
     * @type {SetDatabaseChangeRequestEventData}
     * @memberof SetDatabaseChangeRequestEvent
     */
    'data'?: SetDatabaseChangeRequestEventData;
    /**
     * 
     * @type {string}
     * @memberof SetDatabaseChangeRequestEvent
     */
    'op'?: string;
}
/**
 * 
 * @export
 * @interface SetDatabaseChangeRequestEventData
 */
export interface SetDatabaseChangeRequestEventData {
    /**
     * 
     * @type {SetDatabaseChangeRequestEventDataNew}
     * @memberof SetDatabaseChangeRequestEventData
     */
    'new'?: SetDatabaseChangeRequestEventDataNew;
}
/**
 * 
 * @export
 * @interface SetDatabaseChangeRequestEventDataNew
 */
export interface SetDatabaseChangeRequestEventDataNew {
    /**
     * 
     * @type {number}
     * @memberof SetDatabaseChangeRequestEventDataNew
     */
    'rep_id_pk'?: number;
    /**
     * 
     * @type {string}
     * @memberof SetDatabaseChangeRequestEventDataNew
     */
    'computer_fk'?: string;
    /**
     * 
     * @type {string}
     * @memberof SetDatabaseChangeRequestEventDataNew
     */
    'group_fk'?: string;
    /**
     * 
     * @type {string}
     * @memberof SetDatabaseChangeRequestEventDataNew
     */
    'user_fk'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SetDatabaseChangeRequestEventDataNew
     */
    'is_ioa'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SetDatabaseChangeRequestEventDataNew
     */
    'is_ioe'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SetDatabaseChangeRequestEventDataNew
     */
    'new_value'?: string;
    /**
     * 
     * @type {string}
     * @memberof SetDatabaseChangeRequestEventDataNew
     */
    'old_value'?: string;
    /**
     * 
     * @type {string}
     * @memberof SetDatabaseChangeRequestEventDataNew
     */
    'property_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SetDatabaseChangeRequestEventDataNew
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof SetDatabaseChangeRequestEventDataNew
     */
    'domains_object_guid_fk'?: string;
    /**
     * 
     * @type {string}
     * @memberof SetDatabaseChangeRequestEventDataNew
     */
    'client_uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof SetDatabaseChangeRequestEventDataNew
     */
    'serviceprovider_uuid'?: string;
}
/**
 * 
 * @export
 * @interface SetDatabaseChangeRequestTable
 */
export interface SetDatabaseChangeRequestTable {
    /**
     * 
     * @type {string}
     * @memberof SetDatabaseChangeRequestTable
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SetDatabaseChangeRequestTable
     */
    'schema'?: string;
}
/**
 * 
 * @export
 * @interface SetDatabaseChangeRequestTrigger
 */
export interface SetDatabaseChangeRequestTrigger {
    /**
     * 
     * @type {string}
     * @memberof SetDatabaseChangeRequestTrigger
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface SetEmployeeCountRequest
 */
export interface SetEmployeeCountRequest {
    /**
     * 
     * @type {number}
     * @memberof SetEmployeeCountRequest
     */
    'employeeCount'?: number;
}
/**
 * 
 * @export
 * @interface SetNewCollectorVersionRequest
 */
export interface SetNewCollectorVersionRequest {
    /**
     * 
     * @type {string}
     * @memberof SetNewCollectorVersionRequest
     */
    'version'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SetNewCollectorVersionRequest
     */
    'isPrerelease'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SetNewCollectorVersionRequest
     */
    'secret'?: string;
}
/**
 * 
 * @export
 * @interface SetQuickcheckSettingsRequest
 */
export interface SetQuickcheckSettingsRequest {
    /**
     * 
     * @type {string}
     * @memberof SetQuickcheckSettingsRequest
     */
    'origin': string;
    /**
     * 
     * @type {string}
     * @memberof SetQuickcheckSettingsRequest
     */
    'privacy': string;
}
/**
 * 
 * @export
 * @interface UpdateIoeRequest
 */
export interface UpdateIoeRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateIoeRequest
     */
    'disabled_comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateIoeRequest
     */
    'disabled_reason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateIoeRequest
     */
    'disabled_user_uid'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateIoeRequest
     */
    'is_disabled'?: boolean | null;
}
/**
 * 
 * @export
 * @interface UpdateServiceproviderRequest
 */
export interface UpdateServiceproviderRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceproviderRequest
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceproviderRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceproviderRequest
     */
    'phone'?: string;
}
/**
 * Successful response
 * @export
 * @interface ValidateSession200Response
 */
export interface ValidateSession200Response {
    /**
     * 
     * @type {string}
     * @memberof ValidateSession200Response
     */
    'status'?: string;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new client
         * @param {CreateClientAsAdminRequest} createClientAsAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientAsAdmin: async (createClientAsAdminRequest: CreateClientAsAdminRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createClientAsAdminRequest' is not null or undefined
            assertParamExists('createClientAsAdmin', 'createClientAsAdminRequest', createClientAsAdminRequest)
            const localVarPath = `/admin/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createClientAsAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Rescan all IOEs for this clients
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rescanIoes: async (clientUUID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('rescanIoes', 'clientUUID', clientUUID)
            const localVarPath = `/client/{clientUUID}/audit/rescan`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new client
         * @param {CreateClientAsAdminRequest} createClientAsAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClientAsAdmin(createClientAsAdminRequest: CreateClientAsAdminRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateClientAsAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClientAsAdmin(createClientAsAdminRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.createClientAsAdmin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Rescan all IOEs for this clients
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rescanIoes(clientUUID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateClientAsAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rescanIoes(clientUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.rescanIoes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * Create a new client
         * @param {CreateClientAsAdminRequest} createClientAsAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientAsAdmin(createClientAsAdminRequest: CreateClientAsAdminRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateClientAsAdmin200Response> {
            return localVarFp.createClientAsAdmin(createClientAsAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Rescan all IOEs for this clients
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rescanIoes(clientUUID: string, options?: RawAxiosRequestConfig): AxiosPromise<CreateClientAsAdmin200Response> {
            return localVarFp.rescanIoes(clientUUID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * Create a new client
     * @param {CreateClientAsAdminRequest} createClientAsAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public createClientAsAdmin(createClientAsAdminRequest: CreateClientAsAdminRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).createClientAsAdmin(createClientAsAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Rescan all IOEs for this clients
     * @param {string} clientUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public rescanIoes(clientUUID: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).rescanIoes(clientUUID, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ClientApi - axios parameter creator
 * @export
 */
export const ClientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get client license with data for collector
         * @param {string} clientUUID 
         * @param {ActivateCollectorLicenseRequest} [activateCollectorLicenseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateCollectorLicense: async (clientUUID: string, activateCollectorLicenseRequest?: ActivateCollectorLicenseRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('activateCollectorLicense', 'clientUUID', clientUUID)
            const localVarPath = `/client/{clientUUID}/collector/license/activate`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activateCollectorLicenseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get add new member to client team
         * @param {string} clientUUID 
         * @param {AddClientTeamMemberRequest} [addClientTeamMemberRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClientTeamMember: async (clientUUID: string, addClientTeamMemberRequest?: AddClientTeamMemberRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('addClientTeamMember', 'clientUUID', clientUUID)
            const localVarPath = `/client/{clientUUID}/collaboration/team`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addClientTeamMemberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Trigger security calculation for client
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateSecurityScore: async (clientUUID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('calculateSecurityScore', 'clientUUID', clientUUID)
            const localVarPath = `/client/{clientUUID}/ioe/security-score`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancel subscription for client
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscription: async (clientUUID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('cancelSubscription', 'clientUUID', clientUUID)
            const localVarPath = `/client/{clientUUID}/license/cancel`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check if there is an update available for the collector
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUpdate: async (clientUUID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('checkUpdate', 'clientUUID', clientUUID)
            const localVarPath = `/client/{clientUUID}/collector/update`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new client
         * @param {CreateClientRequest} createClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClient: async (createClientRequest: CreateClientRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createClientRequest' is not null or undefined
            assertParamExists('createClient', 'createClientRequest', createClientRequest)
            const localVarPath = `/client/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createClientRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create new quickcheck client
         * @param {CreateNewQuickcheckClientRequest} [createNewQuickcheckClientRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewQuickcheckClient: async (createNewQuickcheckClientRequest?: CreateNewQuickcheckClientRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/client/quickcheck/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNewQuickcheckClientRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete client
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClient: async (clientUUID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('deleteClient', 'clientUUID', clientUUID)
            const localVarPath = `/client/{clientUUID}`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all IOEs for client
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllIoes: async (clientUUID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('getAllIoes', 'clientUUID', clientUUID)
            const localVarPath = `/client/{clientUUID}/ioe/states`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get client license
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientLicense: async (clientUUID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('getClientLicense', 'clientUUID', clientUUID)
            const localVarPath = `/client/{clientUUID}/license`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get client report pdf
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientReport: async (clientUUID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('getClientReport', 'clientUUID', clientUUID)
            const localVarPath = `/client/{clientUUID}/report`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get client team members
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientTeam: async (clientUUID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('getClientTeam', 'clientUUID', clientUUID)
            const localVarPath = `/client/{clientUUID}/collaboration/team`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get team member
         * @param {string} clientUUID 
         * @param {string} userUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientTeammember: async (clientUUID: string, userUUID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('getClientTeammember', 'clientUUID', clientUUID)
            // verify required parameter 'userUUID' is not null or undefined
            assertParamExists('getClientTeammember', 'userUUID', userUUID)
            const localVarPath = `/client/{clientUUID}/collaboration/team/member/{userUUID}`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)))
                .replace(`{${"userUUID"}}`, encodeURIComponent(String(userUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get current audit status as string - displayed during scan
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectorAuditStatus: async (clientUUID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('getCollectorAuditStatus', 'clientUUID', clientUUID)
            const localVarPath = `/client/{clientUUID}/collector/audit/status`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get collector health status - Determines if collector should be restarted by scheduled task
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectorHealthStatus: async (clientUUID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('getCollectorHealthStatus', 'clientUUID', clientUUID)
            const localVarPath = `/client/{clientUUID}/collector/health`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get client license with data for collector
         * @param {string} clientUUID 
         * @param {ActivateCollectorLicenseRequest} [activateCollectorLicenseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectorLicense: async (clientUUID: string, activateCollectorLicenseRequest?: ActivateCollectorLicenseRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('getCollectorLicense', 'clientUUID', clientUUID)
            const localVarPath = `/client/{clientUUID}/collector/license`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activateCollectorLicenseRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get client contact details
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactDetails: async (clientUUID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('getContactDetails', 'clientUUID', clientUUID)
            const localVarPath = `/client/{clientUUID}/contact`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get installer with config as file, used from dashboard
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstaller: async (clientUUID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('getInstaller', 'clientUUID', clientUUID)
            const localVarPath = `/client/{clientUUID}/collector/installer`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get client license with data for installer
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallerLicense: async (clientUUID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('getInstallerLicense', 'clientUUID', clientUUID)
            const localVarPath = `/client/{clientUUID}/installer/license`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get quickcheck installer with config as file, used from email
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuickcheckInstaller: async (clientUUID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('getQuickcheckInstaller', 'clientUUID', clientUUID)
            const localVarPath = `/client/{clientUUID}/quickcheck/collector/installer`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Report status of initial scan
         * @param {string} clientUUID 
         * @param {InitialScanRequest} [initialScanRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialScan: async (clientUUID: string, initialScanRequest?: InitialScanRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('initialScan', 'clientUUID', clientUUID)
            const localVarPath = `/client/{clientUUID}/collector/initialscan`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initialScanRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove client team member
         * @param {string} clientUUID 
         * @param {RemoveClientTeamMemberRequest} removeClientTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeClientTeamMember: async (clientUUID: string, removeClientTeamMemberRequest: RemoveClientTeamMemberRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('removeClientTeamMember', 'clientUUID', clientUUID)
            // verify required parameter 'removeClientTeamMemberRequest' is not null or undefined
            assertParamExists('removeClientTeamMember', 'removeClientTeamMemberRequest', removeClientTeamMemberRequest)
            const localVarPath = `/client/{clientUUID}/collaboration/team`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeClientTeamMemberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reset all IOEs criticalities for client
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetIoeCriticalities: async (clientUUID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('resetIoeCriticalities', 'clientUUID', clientUUID)
            const localVarPath = `/client/{clientUUID}/ioe/reset`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update client contact details
         * @param {string} clientUUID 
         * @param {SetContactDetailsRequest} [setContactDetailsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setContactDetails: async (clientUUID: string, setContactDetailsRequest?: SetContactDetailsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('setContactDetails', 'clientUUID', clientUUID)
            const localVarPath = `/client/{clientUUID}/contact`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setContactDetailsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set employee count for client
         * @param {string} clientUUID 
         * @param {SetEmployeeCountRequest} [setEmployeeCountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEmployeeCount: async (clientUUID: string, setEmployeeCountRequest?: SetEmployeeCountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('setEmployeeCount', 'clientUUID', clientUUID)
            const localVarPath = `/client/{clientUUID}/employeeCount`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setEmployeeCountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update IoE
         * @param {string} clientUUID 
         * @param {string} ioeId 
         * @param {UpdateIoeRequest} [updateIoeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIoe: async (clientUUID: string, ioeId: string, updateIoeRequest?: UpdateIoeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('updateIoe', 'clientUUID', clientUUID)
            // verify required parameter 'ioeId' is not null or undefined
            assertParamExists('updateIoe', 'ioeId', ioeId)
            const localVarPath = `/client/{clientUUID}/ioe/{ioeId}`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)))
                .replace(`{${"ioeId"}}`, encodeURIComponent(String(ioeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateIoeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientApi - functional programming interface
 * @export
 */
export const ClientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientApiAxiosParamCreator(configuration)
    return {
        /**
         * Get client license with data for collector
         * @param {string} clientUUID 
         * @param {ActivateCollectorLicenseRequest} [activateCollectorLicenseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateCollectorLicense(clientUUID: string, activateCollectorLicenseRequest?: ActivateCollectorLicenseRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivateCollectorLicense201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateCollectorLicense(clientUUID, activateCollectorLicenseRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.activateCollectorLicense']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get add new member to client team
         * @param {string} clientUUID 
         * @param {AddClientTeamMemberRequest} [addClientTeamMemberRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addClientTeamMember(clientUUID: string, addClientTeamMemberRequest?: AddClientTeamMemberRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddClientTeamMember200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addClientTeamMember(clientUUID, addClientTeamMemberRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.addClientTeamMember']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Trigger security calculation for client
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateSecurityScore(clientUUID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateSecurityScore(clientUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.calculateSecurityScore']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Cancel subscription for client
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSubscription(clientUUID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateClientAsAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSubscription(clientUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.cancelSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Check if there is an update available for the collector
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkUpdate(clientUUID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckUpdate200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkUpdate(clientUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.checkUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new client
         * @param {CreateClientRequest} createClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClient(createClientRequest: CreateClientRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateClientAsAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClient(createClientRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.createClient']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create new quickcheck client
         * @param {CreateNewQuickcheckClientRequest} [createNewQuickcheckClientRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewQuickcheckClient(createNewQuickcheckClientRequest?: CreateNewQuickcheckClientRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateClientAsAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewQuickcheckClient(createNewQuickcheckClientRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.createNewQuickcheckClient']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete client
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClient(clientUUID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateClientAsAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClient(clientUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.deleteClient']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all IOEs for client
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllIoes(clientUUID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllIoes200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllIoes(clientUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.getAllIoes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get client license
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientLicense(clientUUID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientLicense200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientLicense(clientUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.getClientLicense']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get client report pdf
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientReport(clientUUID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientReport(clientUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.getClientReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get client team members
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientTeam(clientUUID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetClientTeam200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientTeam(clientUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.getClientTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get team member
         * @param {string} clientUUID 
         * @param {string} userUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientTeammember(clientUUID: string, userUUID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientTeammember200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientTeammember(clientUUID, userUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.getClientTeammember']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get current audit status as string - displayed during scan
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollectorAuditStatus(clientUUID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitialScanRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollectorAuditStatus(clientUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.getCollectorAuditStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get collector health status - Determines if collector should be restarted by scheduled task
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollectorHealthStatus(clientUUID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCollectorHealthStatus200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollectorHealthStatus(clientUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.getCollectorHealthStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get client license with data for collector
         * @param {string} clientUUID 
         * @param {ActivateCollectorLicenseRequest} [activateCollectorLicenseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollectorLicense(clientUUID: string, activateCollectorLicenseRequest?: ActivateCollectorLicenseRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCollectorLicense200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollectorLicense(clientUUID, activateCollectorLicenseRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.getCollectorLicense']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get client contact details
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactDetails(clientUUID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetContactDetails200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContactDetails(clientUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.getContactDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get installer with config as file, used from dashboard
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstaller(clientUUID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstaller(clientUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.getInstaller']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get client license with data for installer
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstallerLicense(clientUUID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInstallerLicense200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstallerLicense(clientUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.getInstallerLicense']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get quickcheck installer with config as file, used from email
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuickcheckInstaller(clientUUID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuickcheckInstaller(clientUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.getQuickcheckInstaller']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Report status of initial scan
         * @param {string} clientUUID 
         * @param {InitialScanRequest} [initialScanRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initialScan(clientUUID: string, initialScanRequest?: InitialScanRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateClientAsAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initialScan(clientUUID, initialScanRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.initialScan']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Remove client team member
         * @param {string} clientUUID 
         * @param {RemoveClientTeamMemberRequest} removeClientTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeClientTeamMember(clientUUID: string, removeClientTeamMemberRequest: RemoveClientTeamMemberRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateClientAsAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeClientTeamMember(clientUUID, removeClientTeamMemberRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.removeClientTeamMember']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Reset all IOEs criticalities for client
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetIoeCriticalities(clientUUID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetIoeCriticalities(clientUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.resetIoeCriticalities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update client contact details
         * @param {string} clientUUID 
         * @param {SetContactDetailsRequest} [setContactDetailsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setContactDetails(clientUUID: string, setContactDetailsRequest?: SetContactDetailsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setContactDetails(clientUUID, setContactDetailsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.setContactDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Set employee count for client
         * @param {string} clientUUID 
         * @param {SetEmployeeCountRequest} [setEmployeeCountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setEmployeeCount(clientUUID: string, setEmployeeCountRequest?: SetEmployeeCountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateClientAsAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setEmployeeCount(clientUUID, setEmployeeCountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.setEmployeeCount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update IoE
         * @param {string} clientUUID 
         * @param {string} ioeId 
         * @param {UpdateIoeRequest} [updateIoeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIoe(clientUUID: string, ioeId: string, updateIoeRequest?: UpdateIoeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateClientAsAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIoe(clientUUID, ioeId, updateIoeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ClientApi.updateIoe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ClientApi - factory interface
 * @export
 */
export const ClientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientApiFp(configuration)
    return {
        /**
         * Get client license with data for collector
         * @param {string} clientUUID 
         * @param {ActivateCollectorLicenseRequest} [activateCollectorLicenseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateCollectorLicense(clientUUID: string, activateCollectorLicenseRequest?: ActivateCollectorLicenseRequest, options?: RawAxiosRequestConfig): AxiosPromise<ActivateCollectorLicense201Response> {
            return localVarFp.activateCollectorLicense(clientUUID, activateCollectorLicenseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get add new member to client team
         * @param {string} clientUUID 
         * @param {AddClientTeamMemberRequest} [addClientTeamMemberRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClientTeamMember(clientUUID: string, addClientTeamMemberRequest?: AddClientTeamMemberRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<AddClientTeamMember200ResponseInner>> {
            return localVarFp.addClientTeamMember(clientUUID, addClientTeamMemberRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Trigger security calculation for client
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateSecurityScore(clientUUID: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.calculateSecurityScore(clientUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Cancel subscription for client
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscription(clientUUID: string, options?: RawAxiosRequestConfig): AxiosPromise<CreateClientAsAdmin200Response> {
            return localVarFp.cancelSubscription(clientUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Check if there is an update available for the collector
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUpdate(clientUUID: string, options?: RawAxiosRequestConfig): AxiosPromise<CheckUpdate200Response> {
            return localVarFp.checkUpdate(clientUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new client
         * @param {CreateClientRequest} createClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClient(createClientRequest: CreateClientRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateClientAsAdmin200Response> {
            return localVarFp.createClient(createClientRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create new quickcheck client
         * @param {CreateNewQuickcheckClientRequest} [createNewQuickcheckClientRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewQuickcheckClient(createNewQuickcheckClientRequest?: CreateNewQuickcheckClientRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateClientAsAdmin200Response> {
            return localVarFp.createNewQuickcheckClient(createNewQuickcheckClientRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete client
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClient(clientUUID: string, options?: RawAxiosRequestConfig): AxiosPromise<CreateClientAsAdmin200Response> {
            return localVarFp.deleteClient(clientUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all IOEs for client
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllIoes(clientUUID: string, options?: RawAxiosRequestConfig): AxiosPromise<GetAllIoes200Response> {
            return localVarFp.getAllIoes(clientUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get client license
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientLicense(clientUUID: string, options?: RawAxiosRequestConfig): AxiosPromise<GetClientLicense200Response> {
            return localVarFp.getClientLicense(clientUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get client report pdf
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientReport(clientUUID: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.getClientReport(clientUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get client team members
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientTeam(clientUUID: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<GetClientTeam200ResponseInner>> {
            return localVarFp.getClientTeam(clientUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get team member
         * @param {string} clientUUID 
         * @param {string} userUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientTeammember(clientUUID: string, userUUID: string, options?: RawAxiosRequestConfig): AxiosPromise<GetClientTeammember200Response> {
            return localVarFp.getClientTeammember(clientUUID, userUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get current audit status as string - displayed during scan
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectorAuditStatus(clientUUID: string, options?: RawAxiosRequestConfig): AxiosPromise<InitialScanRequest> {
            return localVarFp.getCollectorAuditStatus(clientUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get collector health status - Determines if collector should be restarted by scheduled task
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectorHealthStatus(clientUUID: string, options?: RawAxiosRequestConfig): AxiosPromise<GetCollectorHealthStatus200Response> {
            return localVarFp.getCollectorHealthStatus(clientUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get client license with data for collector
         * @param {string} clientUUID 
         * @param {ActivateCollectorLicenseRequest} [activateCollectorLicenseRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollectorLicense(clientUUID: string, activateCollectorLicenseRequest?: ActivateCollectorLicenseRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetCollectorLicense200Response> {
            return localVarFp.getCollectorLicense(clientUUID, activateCollectorLicenseRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get client contact details
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactDetails(clientUUID: string, options?: RawAxiosRequestConfig): AxiosPromise<GetContactDetails200Response> {
            return localVarFp.getContactDetails(clientUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get installer with config as file, used from dashboard
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstaller(clientUUID: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.getInstaller(clientUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get client license with data for installer
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstallerLicense(clientUUID: string, options?: RawAxiosRequestConfig): AxiosPromise<GetInstallerLicense200Response> {
            return localVarFp.getInstallerLicense(clientUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get quickcheck installer with config as file, used from email
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuickcheckInstaller(clientUUID: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.getQuickcheckInstaller(clientUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Report status of initial scan
         * @param {string} clientUUID 
         * @param {InitialScanRequest} [initialScanRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initialScan(clientUUID: string, initialScanRequest?: InitialScanRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateClientAsAdmin200Response> {
            return localVarFp.initialScan(clientUUID, initialScanRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove client team member
         * @param {string} clientUUID 
         * @param {RemoveClientTeamMemberRequest} removeClientTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeClientTeamMember(clientUUID: string, removeClientTeamMemberRequest: RemoveClientTeamMemberRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateClientAsAdmin200Response> {
            return localVarFp.removeClientTeamMember(clientUUID, removeClientTeamMemberRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Reset all IOEs criticalities for client
         * @param {string} clientUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetIoeCriticalities(clientUUID: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.resetIoeCriticalities(clientUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Update client contact details
         * @param {string} clientUUID 
         * @param {SetContactDetailsRequest} [setContactDetailsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setContactDetails(clientUUID: string, setContactDetailsRequest?: SetContactDetailsRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.setContactDetails(clientUUID, setContactDetailsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Set employee count for client
         * @param {string} clientUUID 
         * @param {SetEmployeeCountRequest} [setEmployeeCountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEmployeeCount(clientUUID: string, setEmployeeCountRequest?: SetEmployeeCountRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateClientAsAdmin200Response> {
            return localVarFp.setEmployeeCount(clientUUID, setEmployeeCountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update IoE
         * @param {string} clientUUID 
         * @param {string} ioeId 
         * @param {UpdateIoeRequest} [updateIoeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIoe(clientUUID: string, ioeId: string, updateIoeRequest?: UpdateIoeRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateClientAsAdmin200Response> {
            return localVarFp.updateIoe(clientUUID, ioeId, updateIoeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientApi - object-oriented interface
 * @export
 * @class ClientApi
 * @extends {BaseAPI}
 */
export class ClientApi extends BaseAPI {
    /**
     * Get client license with data for collector
     * @param {string} clientUUID 
     * @param {ActivateCollectorLicenseRequest} [activateCollectorLicenseRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public activateCollectorLicense(clientUUID: string, activateCollectorLicenseRequest?: ActivateCollectorLicenseRequest, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).activateCollectorLicense(clientUUID, activateCollectorLicenseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get add new member to client team
     * @param {string} clientUUID 
     * @param {AddClientTeamMemberRequest} [addClientTeamMemberRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public addClientTeamMember(clientUUID: string, addClientTeamMemberRequest?: AddClientTeamMemberRequest, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).addClientTeamMember(clientUUID, addClientTeamMemberRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Trigger security calculation for client
     * @param {string} clientUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public calculateSecurityScore(clientUUID: string, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).calculateSecurityScore(clientUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cancel subscription for client
     * @param {string} clientUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public cancelSubscription(clientUUID: string, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).cancelSubscription(clientUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check if there is an update available for the collector
     * @param {string} clientUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public checkUpdate(clientUUID: string, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).checkUpdate(clientUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new client
     * @param {CreateClientRequest} createClientRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public createClient(createClientRequest: CreateClientRequest, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).createClient(createClientRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create new quickcheck client
     * @param {CreateNewQuickcheckClientRequest} [createNewQuickcheckClientRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public createNewQuickcheckClient(createNewQuickcheckClientRequest?: CreateNewQuickcheckClientRequest, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).createNewQuickcheckClient(createNewQuickcheckClientRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete client
     * @param {string} clientUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public deleteClient(clientUUID: string, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).deleteClient(clientUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all IOEs for client
     * @param {string} clientUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getAllIoes(clientUUID: string, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).getAllIoes(clientUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get client license
     * @param {string} clientUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getClientLicense(clientUUID: string, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).getClientLicense(clientUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get client report pdf
     * @param {string} clientUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getClientReport(clientUUID: string, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).getClientReport(clientUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get client team members
     * @param {string} clientUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getClientTeam(clientUUID: string, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).getClientTeam(clientUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get team member
     * @param {string} clientUUID 
     * @param {string} userUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getClientTeammember(clientUUID: string, userUUID: string, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).getClientTeammember(clientUUID, userUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get current audit status as string - displayed during scan
     * @param {string} clientUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getCollectorAuditStatus(clientUUID: string, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).getCollectorAuditStatus(clientUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get collector health status - Determines if collector should be restarted by scheduled task
     * @param {string} clientUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getCollectorHealthStatus(clientUUID: string, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).getCollectorHealthStatus(clientUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get client license with data for collector
     * @param {string} clientUUID 
     * @param {ActivateCollectorLicenseRequest} [activateCollectorLicenseRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getCollectorLicense(clientUUID: string, activateCollectorLicenseRequest?: ActivateCollectorLicenseRequest, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).getCollectorLicense(clientUUID, activateCollectorLicenseRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get client contact details
     * @param {string} clientUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getContactDetails(clientUUID: string, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).getContactDetails(clientUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get installer with config as file, used from dashboard
     * @param {string} clientUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getInstaller(clientUUID: string, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).getInstaller(clientUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get client license with data for installer
     * @param {string} clientUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getInstallerLicense(clientUUID: string, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).getInstallerLicense(clientUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get quickcheck installer with config as file, used from email
     * @param {string} clientUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getQuickcheckInstaller(clientUUID: string, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).getQuickcheckInstaller(clientUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Report status of initial scan
     * @param {string} clientUUID 
     * @param {InitialScanRequest} [initialScanRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public initialScan(clientUUID: string, initialScanRequest?: InitialScanRequest, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).initialScan(clientUUID, initialScanRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove client team member
     * @param {string} clientUUID 
     * @param {RemoveClientTeamMemberRequest} removeClientTeamMemberRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public removeClientTeamMember(clientUUID: string, removeClientTeamMemberRequest: RemoveClientTeamMemberRequest, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).removeClientTeamMember(clientUUID, removeClientTeamMemberRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reset all IOEs criticalities for client
     * @param {string} clientUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public resetIoeCriticalities(clientUUID: string, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).resetIoeCriticalities(clientUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update client contact details
     * @param {string} clientUUID 
     * @param {SetContactDetailsRequest} [setContactDetailsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public setContactDetails(clientUUID: string, setContactDetailsRequest?: SetContactDetailsRequest, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).setContactDetails(clientUUID, setContactDetailsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set employee count for client
     * @param {string} clientUUID 
     * @param {SetEmployeeCountRequest} [setEmployeeCountRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public setEmployeeCount(clientUUID: string, setEmployeeCountRequest?: SetEmployeeCountRequest, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).setEmployeeCount(clientUUID, setEmployeeCountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update IoE
     * @param {string} clientUUID 
     * @param {string} ioeId 
     * @param {UpdateIoeRequest} [updateIoeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public updateIoe(clientUUID: string, ioeId: string, updateIoeRequest?: UpdateIoeRequest, options?: RawAxiosRequestConfig) {
        return ClientApiFp(this.configuration).updateIoe(clientUUID, ioeId, updateIoeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get client IoA event log
         * @param {string} clientUUID 
         * @param {number} [pageSize] Number of items to return
         * @param {number} [page] Number of items to skip (0 - start at the beginning)
         * @param {string} [startAt] Start date and time of the log entries 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetIoaLog: async (clientUUID: string, pageSize?: number, page?: number, startAt?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('customerGetIoaLog', 'clientUUID', clientUUID)
            const localVarPath = `/api/v1/client/{clientUUID}/ioa`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (startAt !== undefined) {
                localVarQueryParameter['startAt'] = (startAt as any instanceof Date) ?
                    (startAt as any).toISOString() :
                    startAt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get client IoE event log
         * @param {string} clientUUID 
         * @param {number} [pageSize] Number of items to return
         * @param {number} [page] Number of items to skip (0 - start at the beginning)
         * @param {string} [startAt] Start date and time of the log entries 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetIoeLog: async (clientUUID: string, pageSize?: number, page?: number, startAt?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientUUID' is not null or undefined
            assertParamExists('customerGetIoeLog', 'clientUUID', clientUUID)
            const localVarPath = `/api/v1/client/{clientUUID}/ioe`
                .replace(`{${"clientUUID"}}`, encodeURIComponent(String(clientUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (startAt !== undefined) {
                localVarQueryParameter['startAt'] = (startAt as any instanceof Date) ?
                    (startAt as any).toISOString() :
                    startAt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * Get client IoA event log
         * @param {string} clientUUID 
         * @param {number} [pageSize] Number of items to return
         * @param {number} [page] Number of items to skip (0 - start at the beginning)
         * @param {string} [startAt] Start date and time of the log entries 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetIoaLog(clientUUID: string, pageSize?: number, page?: number, startAt?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerGetIoaLog200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetIoaLog(clientUUID, pageSize, page, startAt, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.customerGetIoaLog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get client IoE event log
         * @param {string} clientUUID 
         * @param {number} [pageSize] Number of items to return
         * @param {number} [page] Number of items to skip (0 - start at the beginning)
         * @param {string} [startAt] Start date and time of the log entries 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetIoeLog(clientUUID: string, pageSize?: number, page?: number, startAt?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerGetIoeLog200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetIoeLog(clientUUID, pageSize, page, startAt, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CustomerApi.customerGetIoeLog']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * Get client IoA event log
         * @param {string} clientUUID 
         * @param {number} [pageSize] Number of items to return
         * @param {number} [page] Number of items to skip (0 - start at the beginning)
         * @param {string} [startAt] Start date and time of the log entries 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetIoaLog(clientUUID: string, pageSize?: number, page?: number, startAt?: string, options?: RawAxiosRequestConfig): AxiosPromise<CustomerGetIoaLog200Response> {
            return localVarFp.customerGetIoaLog(clientUUID, pageSize, page, startAt, options).then((request) => request(axios, basePath));
        },
        /**
         * Get client IoE event log
         * @param {string} clientUUID 
         * @param {number} [pageSize] Number of items to return
         * @param {number} [page] Number of items to skip (0 - start at the beginning)
         * @param {string} [startAt] Start date and time of the log entries 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetIoeLog(clientUUID: string, pageSize?: number, page?: number, startAt?: string, options?: RawAxiosRequestConfig): AxiosPromise<CustomerGetIoeLog200Response> {
            return localVarFp.customerGetIoeLog(clientUUID, pageSize, page, startAt, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * Get client IoA event log
     * @param {string} clientUUID 
     * @param {number} [pageSize] Number of items to return
     * @param {number} [page] Number of items to skip (0 - start at the beginning)
     * @param {string} [startAt] Start date and time of the log entries 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerGetIoaLog(clientUUID: string, pageSize?: number, page?: number, startAt?: string, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).customerGetIoaLog(clientUUID, pageSize, page, startAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get client IoE event log
     * @param {string} clientUUID 
     * @param {number} [pageSize] Number of items to return
     * @param {number} [page] Number of items to skip (0 - start at the beginning)
     * @param {string} [startAt] Start date and time of the log entries 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerGetIoeLog(clientUUID: string, pageSize?: number, page?: number, startAt?: string, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).customerGetIoeLog(clientUUID, pageSize, page, startAt, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OtherApi - axios parameter creator
 * @export
 */
export const OtherApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get url to collector and updater, used for installer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExecutables: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/services/executables`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get api health status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/monitoring/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles external stripe webhooks
         * @param {string} [stripeSignature] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleStripeWebhooks: async (stripeSignature?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/billing/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stripeSignature != null) {
                localVarHeaderParameter['stripe-signature'] = String(stripeSignature);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send email with data from website contact form
         * @param {SendContactFormRequest} sendContactFormRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendContactForm: async (sendContactFormRequest: SendContactFormRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendContactFormRequest' is not null or undefined
            assertParamExists('sendContactForm', 'sendContactFormRequest', sendContactFormRequest)
            const localVarPath = `/services/mailer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendContactFormRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Notify backend on data change, called via hasura event webhook
         * @param {SetDatabaseChangeRequest} [setDatabaseChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDatabaseChange: async (setDatabaseChangeRequest?: SetDatabaseChangeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/services/ioe/data-change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setDatabaseChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set new collector version, called via github webhook
         * @param {SetNewCollectorVersionRequest} [setNewCollectorVersionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNewCollectorVersion: async (setNewCollectorVersionRequest?: SetNewCollectorVersionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/services/collector/version/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setNewCollectorVersionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OtherApi - functional programming interface
 * @export
 */
export const OtherApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OtherApiAxiosParamCreator(configuration)
    return {
        /**
         * Get url to collector and updater, used for installer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExecutables(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetExecutables200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExecutables(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OtherApi.getExecutables']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * get api health status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHealth(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHealth200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHealth(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OtherApi.getHealth']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Handles external stripe webhooks
         * @param {string} [stripeSignature] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleStripeWebhooks(stripeSignature?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleStripeWebhooks(stripeSignature, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OtherApi.handleStripeWebhooks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Send email with data from website contact form
         * @param {SendContactFormRequest} sendContactFormRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendContactForm(sendContactFormRequest: SendContactFormRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateClientAsAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendContactForm(sendContactFormRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OtherApi.sendContactForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Notify backend on data change, called via hasura event webhook
         * @param {SetDatabaseChangeRequest} [setDatabaseChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDatabaseChange(setDatabaseChangeRequest?: SetDatabaseChangeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateClientAsAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDatabaseChange(setDatabaseChangeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OtherApi.setDatabaseChange']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Set new collector version, called via github webhook
         * @param {SetNewCollectorVersionRequest} [setNewCollectorVersionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setNewCollectorVersion(setNewCollectorVersionRequest?: SetNewCollectorVersionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateClientAsAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setNewCollectorVersion(setNewCollectorVersionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OtherApi.setNewCollectorVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OtherApi - factory interface
 * @export
 */
export const OtherApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OtherApiFp(configuration)
    return {
        /**
         * Get url to collector and updater, used for installer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExecutables(options?: RawAxiosRequestConfig): AxiosPromise<GetExecutables200Response> {
            return localVarFp.getExecutables(options).then((request) => request(axios, basePath));
        },
        /**
         * get api health status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHealth(options?: RawAxiosRequestConfig): AxiosPromise<GetHealth200Response> {
            return localVarFp.getHealth(options).then((request) => request(axios, basePath));
        },
        /**
         * Handles external stripe webhooks
         * @param {string} [stripeSignature] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleStripeWebhooks(stripeSignature?: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.handleStripeWebhooks(stripeSignature, options).then((request) => request(axios, basePath));
        },
        /**
         * Send email with data from website contact form
         * @param {SendContactFormRequest} sendContactFormRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendContactForm(sendContactFormRequest: SendContactFormRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateClientAsAdmin200Response> {
            return localVarFp.sendContactForm(sendContactFormRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Notify backend on data change, called via hasura event webhook
         * @param {SetDatabaseChangeRequest} [setDatabaseChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDatabaseChange(setDatabaseChangeRequest?: SetDatabaseChangeRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateClientAsAdmin200Response> {
            return localVarFp.setDatabaseChange(setDatabaseChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Set new collector version, called via github webhook
         * @param {SetNewCollectorVersionRequest} [setNewCollectorVersionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNewCollectorVersion(setNewCollectorVersionRequest?: SetNewCollectorVersionRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateClientAsAdmin200Response> {
            return localVarFp.setNewCollectorVersion(setNewCollectorVersionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OtherApi - object-oriented interface
 * @export
 * @class OtherApi
 * @extends {BaseAPI}
 */
export class OtherApi extends BaseAPI {
    /**
     * Get url to collector and updater, used for installer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtherApi
     */
    public getExecutables(options?: RawAxiosRequestConfig) {
        return OtherApiFp(this.configuration).getExecutables(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get api health status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtherApi
     */
    public getHealth(options?: RawAxiosRequestConfig) {
        return OtherApiFp(this.configuration).getHealth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles external stripe webhooks
     * @param {string} [stripeSignature] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtherApi
     */
    public handleStripeWebhooks(stripeSignature?: string, options?: RawAxiosRequestConfig) {
        return OtherApiFp(this.configuration).handleStripeWebhooks(stripeSignature, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send email with data from website contact form
     * @param {SendContactFormRequest} sendContactFormRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtherApi
     */
    public sendContactForm(sendContactFormRequest: SendContactFormRequest, options?: RawAxiosRequestConfig) {
        return OtherApiFp(this.configuration).sendContactForm(sendContactFormRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Notify backend on data change, called via hasura event webhook
     * @param {SetDatabaseChangeRequest} [setDatabaseChangeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtherApi
     */
    public setDatabaseChange(setDatabaseChangeRequest?: SetDatabaseChangeRequest, options?: RawAxiosRequestConfig) {
        return OtherApiFp(this.configuration).setDatabaseChange(setDatabaseChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set new collector version, called via github webhook
     * @param {SetNewCollectorVersionRequest} [setNewCollectorVersionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OtherApi
     */
    public setNewCollectorVersion(setNewCollectorVersionRequest?: SetNewCollectorVersionRequest, options?: RawAxiosRequestConfig) {
        return OtherApiFp(this.configuration).setNewCollectorVersion(setNewCollectorVersionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ServiceproviderApi - axios parameter creator
 * @export
 */
export const ServiceproviderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add member to serviceprovider team
         * @param {string} serviceproviderUUID 
         * @param {AddServiceproviderTeamMemberRequest} addServiceproviderTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addServiceproviderTeamMember: async (serviceproviderUUID: string, addServiceproviderTeamMemberRequest: AddServiceproviderTeamMemberRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceproviderUUID' is not null or undefined
            assertParamExists('addServiceproviderTeamMember', 'serviceproviderUUID', serviceproviderUUID)
            // verify required parameter 'addServiceproviderTeamMemberRequest' is not null or undefined
            assertParamExists('addServiceproviderTeamMember', 'addServiceproviderTeamMemberRequest', addServiceproviderTeamMemberRequest)
            const localVarPath = `/serviceprovider/{serviceproviderUUID}/collaboration/team`
                .replace(`{${"serviceproviderUUID"}}`, encodeURIComponent(String(serviceproviderUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addServiceproviderTeamMemberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get stripe checkout session
         * @param {string} serviceproviderUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCheckoutSession: async (serviceproviderUUID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceproviderUUID' is not null or undefined
            assertParamExists('createCheckoutSession', 'serviceproviderUUID', serviceproviderUUID)
            const localVarPath = `/serviceprovider/{serviceproviderUUID}/checkout-session`
                .replace(`{${"serviceproviderUUID"}}`, encodeURIComponent(String(serviceproviderUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new serviceprovider
         * @param {CreateServiceproviderRequest} createServiceproviderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceprovider: async (createServiceproviderRequest: CreateServiceproviderRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createServiceproviderRequest' is not null or undefined
            assertParamExists('createServiceprovider', 'createServiceproviderRequest', createServiceproviderRequest)
            const localVarPath = `/serviceprovider/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createServiceproviderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete serviceprovider
         * @param {string} serviceproviderUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServiceprovider: async (serviceproviderUUID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceproviderUUID' is not null or undefined
            assertParamExists('deleteServiceprovider', 'serviceproviderUUID', serviceproviderUUID)
            const localVarPath = `/serviceprovider/{serviceproviderUUID}`
                .replace(`{${"serviceproviderUUID"}}`, encodeURIComponent(String(serviceproviderUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get serviceprovider details
         * @param {string} serviceproviderUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceProviderTeam: async (serviceproviderUUID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceproviderUUID' is not null or undefined
            assertParamExists('getServiceProviderTeam', 'serviceproviderUUID', serviceproviderUUID)
            const localVarPath = `/serviceprovider/{serviceproviderUUID}/collaboration/team`
                .replace(`{${"serviceproviderUUID"}}`, encodeURIComponent(String(serviceproviderUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get serviceprovider details
         * @param {string} serviceproviderUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceprovider: async (serviceproviderUUID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceproviderUUID' is not null or undefined
            assertParamExists('getServiceprovider', 'serviceproviderUUID', serviceproviderUUID)
            const localVarPath = `/serviceprovider/{serviceproviderUUID}`
                .replace(`{${"serviceproviderUUID"}}`, encodeURIComponent(String(serviceproviderUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remover member from team
         * @param {string} serviceproviderUUID 
         * @param {RemoveClientTeamMemberRequest} removeClientTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeServiceproviderTeamMember: async (serviceproviderUUID: string, removeClientTeamMemberRequest: RemoveClientTeamMemberRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceproviderUUID' is not null or undefined
            assertParamExists('removeServiceproviderTeamMember', 'serviceproviderUUID', serviceproviderUUID)
            // verify required parameter 'removeClientTeamMemberRequest' is not null or undefined
            assertParamExists('removeServiceproviderTeamMember', 'removeClientTeamMemberRequest', removeClientTeamMemberRequest)
            const localVarPath = `/serviceprovider/{serviceproviderUUID}/collaboration/team`
                .replace(`{${"serviceproviderUUID"}}`, encodeURIComponent(String(serviceproviderUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeClientTeamMemberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set quickcheck settings
         * @param {string} serviceproviderUUID 
         * @param {SetQuickcheckSettingsRequest} setQuickcheckSettingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setQuickcheckSettings: async (serviceproviderUUID: string, setQuickcheckSettingsRequest: SetQuickcheckSettingsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceproviderUUID' is not null or undefined
            assertParamExists('setQuickcheckSettings', 'serviceproviderUUID', serviceproviderUUID)
            // verify required parameter 'setQuickcheckSettingsRequest' is not null or undefined
            assertParamExists('setQuickcheckSettings', 'setQuickcheckSettingsRequest', setQuickcheckSettingsRequest)
            const localVarPath = `/serviceprovider/{serviceproviderUUID}/quickcheck/settings`
                .replace(`{${"serviceproviderUUID"}}`, encodeURIComponent(String(serviceproviderUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setQuickcheckSettingsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update serviceprovider details
         * @param {string} serviceproviderUUID 
         * @param {UpdateServiceproviderRequest} updateServiceproviderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceprovider: async (serviceproviderUUID: string, updateServiceproviderRequest: UpdateServiceproviderRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceproviderUUID' is not null or undefined
            assertParamExists('updateServiceprovider', 'serviceproviderUUID', serviceproviderUUID)
            // verify required parameter 'updateServiceproviderRequest' is not null or undefined
            assertParamExists('updateServiceprovider', 'updateServiceproviderRequest', updateServiceproviderRequest)
            const localVarPath = `/serviceprovider/{serviceproviderUUID}/whitelabel`
                .replace(`{${"serviceproviderUUID"}}`, encodeURIComponent(String(serviceproviderUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateServiceproviderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload whitelabel logo
         * @param {string} serviceproviderUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLogo: async (serviceproviderUUID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceproviderUUID' is not null or undefined
            assertParamExists('uploadLogo', 'serviceproviderUUID', serviceproviderUUID)
            const localVarPath = `/serviceprovider/{serviceproviderUUID}/whitelabel/logo`
                .replace(`{${"serviceproviderUUID"}}`, encodeURIComponent(String(serviceproviderUUID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceproviderApi - functional programming interface
 * @export
 */
export const ServiceproviderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceproviderApiAxiosParamCreator(configuration)
    return {
        /**
         * Add member to serviceprovider team
         * @param {string} serviceproviderUUID 
         * @param {AddServiceproviderTeamMemberRequest} addServiceproviderTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addServiceproviderTeamMember(serviceproviderUUID: string, addServiceproviderTeamMemberRequest: AddServiceproviderTeamMemberRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddServiceproviderTeamMember201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addServiceproviderTeamMember(serviceproviderUUID, addServiceproviderTeamMemberRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceproviderApi.addServiceproviderTeamMember']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get stripe checkout session
         * @param {string} serviceproviderUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCheckoutSession(serviceproviderUUID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCheckoutSession(serviceproviderUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceproviderApi.createCheckoutSession']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new serviceprovider
         * @param {CreateServiceproviderRequest} createServiceproviderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createServiceprovider(createServiceproviderRequest: CreateServiceproviderRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateServiceprovider201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createServiceprovider(createServiceproviderRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceproviderApi.createServiceprovider']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete serviceprovider
         * @param {string} serviceproviderUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteServiceprovider(serviceproviderUUID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateClientAsAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteServiceprovider(serviceproviderUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceproviderApi.deleteServiceprovider']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get serviceprovider details
         * @param {string} serviceproviderUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceProviderTeam(serviceproviderUUID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetClientTeam200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceProviderTeam(serviceproviderUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceproviderApi.getServiceProviderTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get serviceprovider details
         * @param {string} serviceproviderUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceprovider(serviceproviderUUID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetServiceprovider200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceprovider(serviceproviderUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceproviderApi.getServiceprovider']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Remover member from team
         * @param {string} serviceproviderUUID 
         * @param {RemoveClientTeamMemberRequest} removeClientTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeServiceproviderTeamMember(serviceproviderUUID: string, removeClientTeamMemberRequest: RemoveClientTeamMemberRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateClientAsAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeServiceproviderTeamMember(serviceproviderUUID, removeClientTeamMemberRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceproviderApi.removeServiceproviderTeamMember']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Set quickcheck settings
         * @param {string} serviceproviderUUID 
         * @param {SetQuickcheckSettingsRequest} setQuickcheckSettingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setQuickcheckSettings(serviceproviderUUID: string, setQuickcheckSettingsRequest: SetQuickcheckSettingsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateClientAsAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setQuickcheckSettings(serviceproviderUUID, setQuickcheckSettingsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceproviderApi.setQuickcheckSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update serviceprovider details
         * @param {string} serviceproviderUUID 
         * @param {UpdateServiceproviderRequest} updateServiceproviderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateServiceprovider(serviceproviderUUID: string, updateServiceproviderRequest: UpdateServiceproviderRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateClientAsAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateServiceprovider(serviceproviderUUID, updateServiceproviderRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceproviderApi.updateServiceprovider']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Upload whitelabel logo
         * @param {string} serviceproviderUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadLogo(serviceproviderUUID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadLogo(serviceproviderUUID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ServiceproviderApi.uploadLogo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ServiceproviderApi - factory interface
 * @export
 */
export const ServiceproviderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceproviderApiFp(configuration)
    return {
        /**
         * Add member to serviceprovider team
         * @param {string} serviceproviderUUID 
         * @param {AddServiceproviderTeamMemberRequest} addServiceproviderTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addServiceproviderTeamMember(serviceproviderUUID: string, addServiceproviderTeamMemberRequest: AddServiceproviderTeamMemberRequest, options?: RawAxiosRequestConfig): AxiosPromise<AddServiceproviderTeamMember201Response> {
            return localVarFp.addServiceproviderTeamMember(serviceproviderUUID, addServiceproviderTeamMemberRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get stripe checkout session
         * @param {string} serviceproviderUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCheckoutSession(serviceproviderUUID: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createCheckoutSession(serviceproviderUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new serviceprovider
         * @param {CreateServiceproviderRequest} createServiceproviderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceprovider(createServiceproviderRequest: CreateServiceproviderRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateServiceprovider201Response> {
            return localVarFp.createServiceprovider(createServiceproviderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete serviceprovider
         * @param {string} serviceproviderUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServiceprovider(serviceproviderUUID: string, options?: RawAxiosRequestConfig): AxiosPromise<CreateClientAsAdmin200Response> {
            return localVarFp.deleteServiceprovider(serviceproviderUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get serviceprovider details
         * @param {string} serviceproviderUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceProviderTeam(serviceproviderUUID: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<GetClientTeam200ResponseInner>> {
            return localVarFp.getServiceProviderTeam(serviceproviderUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get serviceprovider details
         * @param {string} serviceproviderUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceprovider(serviceproviderUUID: string, options?: RawAxiosRequestConfig): AxiosPromise<GetServiceprovider200Response> {
            return localVarFp.getServiceprovider(serviceproviderUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * Remover member from team
         * @param {string} serviceproviderUUID 
         * @param {RemoveClientTeamMemberRequest} removeClientTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeServiceproviderTeamMember(serviceproviderUUID: string, removeClientTeamMemberRequest: RemoveClientTeamMemberRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateClientAsAdmin200Response> {
            return localVarFp.removeServiceproviderTeamMember(serviceproviderUUID, removeClientTeamMemberRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Set quickcheck settings
         * @param {string} serviceproviderUUID 
         * @param {SetQuickcheckSettingsRequest} setQuickcheckSettingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setQuickcheckSettings(serviceproviderUUID: string, setQuickcheckSettingsRequest: SetQuickcheckSettingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateClientAsAdmin200Response> {
            return localVarFp.setQuickcheckSettings(serviceproviderUUID, setQuickcheckSettingsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update serviceprovider details
         * @param {string} serviceproviderUUID 
         * @param {UpdateServiceproviderRequest} updateServiceproviderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceprovider(serviceproviderUUID: string, updateServiceproviderRequest: UpdateServiceproviderRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateClientAsAdmin200Response> {
            return localVarFp.updateServiceprovider(serviceproviderUUID, updateServiceproviderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload whitelabel logo
         * @param {string} serviceproviderUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLogo(serviceproviderUUID: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.uploadLogo(serviceproviderUUID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServiceproviderApi - object-oriented interface
 * @export
 * @class ServiceproviderApi
 * @extends {BaseAPI}
 */
export class ServiceproviderApi extends BaseAPI {
    /**
     * Add member to serviceprovider team
     * @param {string} serviceproviderUUID 
     * @param {AddServiceproviderTeamMemberRequest} addServiceproviderTeamMemberRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceproviderApi
     */
    public addServiceproviderTeamMember(serviceproviderUUID: string, addServiceproviderTeamMemberRequest: AddServiceproviderTeamMemberRequest, options?: RawAxiosRequestConfig) {
        return ServiceproviderApiFp(this.configuration).addServiceproviderTeamMember(serviceproviderUUID, addServiceproviderTeamMemberRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get stripe checkout session
     * @param {string} serviceproviderUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceproviderApi
     */
    public createCheckoutSession(serviceproviderUUID: string, options?: RawAxiosRequestConfig) {
        return ServiceproviderApiFp(this.configuration).createCheckoutSession(serviceproviderUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new serviceprovider
     * @param {CreateServiceproviderRequest} createServiceproviderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceproviderApi
     */
    public createServiceprovider(createServiceproviderRequest: CreateServiceproviderRequest, options?: RawAxiosRequestConfig) {
        return ServiceproviderApiFp(this.configuration).createServiceprovider(createServiceproviderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete serviceprovider
     * @param {string} serviceproviderUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceproviderApi
     */
    public deleteServiceprovider(serviceproviderUUID: string, options?: RawAxiosRequestConfig) {
        return ServiceproviderApiFp(this.configuration).deleteServiceprovider(serviceproviderUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get serviceprovider details
     * @param {string} serviceproviderUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceproviderApi
     */
    public getServiceProviderTeam(serviceproviderUUID: string, options?: RawAxiosRequestConfig) {
        return ServiceproviderApiFp(this.configuration).getServiceProviderTeam(serviceproviderUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get serviceprovider details
     * @param {string} serviceproviderUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceproviderApi
     */
    public getServiceprovider(serviceproviderUUID: string, options?: RawAxiosRequestConfig) {
        return ServiceproviderApiFp(this.configuration).getServiceprovider(serviceproviderUUID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remover member from team
     * @param {string} serviceproviderUUID 
     * @param {RemoveClientTeamMemberRequest} removeClientTeamMemberRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceproviderApi
     */
    public removeServiceproviderTeamMember(serviceproviderUUID: string, removeClientTeamMemberRequest: RemoveClientTeamMemberRequest, options?: RawAxiosRequestConfig) {
        return ServiceproviderApiFp(this.configuration).removeServiceproviderTeamMember(serviceproviderUUID, removeClientTeamMemberRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set quickcheck settings
     * @param {string} serviceproviderUUID 
     * @param {SetQuickcheckSettingsRequest} setQuickcheckSettingsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceproviderApi
     */
    public setQuickcheckSettings(serviceproviderUUID: string, setQuickcheckSettingsRequest: SetQuickcheckSettingsRequest, options?: RawAxiosRequestConfig) {
        return ServiceproviderApiFp(this.configuration).setQuickcheckSettings(serviceproviderUUID, setQuickcheckSettingsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update serviceprovider details
     * @param {string} serviceproviderUUID 
     * @param {UpdateServiceproviderRequest} updateServiceproviderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceproviderApi
     */
    public updateServiceprovider(serviceproviderUUID: string, updateServiceproviderRequest: UpdateServiceproviderRequest, options?: RawAxiosRequestConfig) {
        return ServiceproviderApiFp(this.configuration).updateServiceprovider(serviceproviderUUID, updateServiceproviderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload whitelabel logo
     * @param {string} serviceproviderUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceproviderApi
     */
    public uploadLogo(serviceproviderUUID: string, options?: RawAxiosRequestConfig) {
        return ServiceproviderApiFp(this.configuration).uploadLogo(serviceproviderUUID, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * End user Keycloak user session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endKeycloakSession: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/keycloak/end-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Keycloak password change link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPasswordChangeLink: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/password/change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update email subscription user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionUpdateEmail: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/email-subscription/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Keycloak user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Keycloak webhooks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleUserWebhooks: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update user
         * @param {GetUser200Response} getUser200Response Keycloak user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (getUser200Response: GetUser200Response, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getUser200Response' is not null or undefined
            assertParamExists('updateUser', 'getUser200Response', getUser200Response)
            const localVarPath = `/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getUser200Response, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validate user session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateSession: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/session/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * End user Keycloak user session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endKeycloakSession(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.endKeycloakSession(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.endKeycloakSession']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get Keycloak password change link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPasswordChangeLink(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPasswordChangeLink200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPasswordChangeLink(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getPasswordChangeLink']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * update email subscription user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionUpdateEmail(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionUpdateEmail(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getSubscriptionUpdateEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get Keycloak user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUser200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.getUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get Keycloak webhooks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleUserWebhooks(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleUserWebhooks(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.handleUserWebhooks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update user
         * @param {GetUser200Response} getUser200Response Keycloak user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(getUser200Response: GetUser200Response, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(getUser200Response, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.updateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Validate user session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateSession(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateSession200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateSession(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.validateSession']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * End user Keycloak user session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endKeycloakSession(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.endKeycloakSession(options).then((request) => request(axios, basePath));
        },
        /**
         * Get Keycloak password change link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPasswordChangeLink(options?: RawAxiosRequestConfig): AxiosPromise<GetPasswordChangeLink200Response> {
            return localVarFp.getPasswordChangeLink(options).then((request) => request(axios, basePath));
        },
        /**
         * update email subscription user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionUpdateEmail(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.getSubscriptionUpdateEmail(options).then((request) => request(axios, basePath));
        },
        /**
         * Get Keycloak user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(options?: RawAxiosRequestConfig): AxiosPromise<GetUser200Response> {
            return localVarFp.getUser(options).then((request) => request(axios, basePath));
        },
        /**
         * Get Keycloak webhooks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleUserWebhooks(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.handleUserWebhooks(options).then((request) => request(axios, basePath));
        },
        /**
         * Update user
         * @param {GetUser200Response} getUser200Response Keycloak user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(getUser200Response: GetUser200Response, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateUser(getUser200Response, options).then((request) => request(axios, basePath));
        },
        /**
         * Validate user session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateSession(options?: RawAxiosRequestConfig): AxiosPromise<ValidateSession200Response> {
            return localVarFp.validateSession(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * End user Keycloak user session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public endKeycloakSession(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).endKeycloakSession(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Keycloak password change link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getPasswordChangeLink(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getPasswordChangeLink(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update email subscription user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getSubscriptionUpdateEmail(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getSubscriptionUpdateEmail(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Keycloak user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).getUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Keycloak webhooks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public handleUserWebhooks(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).handleUserWebhooks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update user
     * @param {GetUser200Response} getUser200Response Keycloak user object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(getUser200Response: GetUser200Response, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUser(getUser200Response, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validate user session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public validateSession(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).validateSession(options).then((request) => request(this.axios, this.basePath));
    }
}



