import { defineNuxtRouteMiddleware } from 'nuxt/app'
import { getMainRole } from '~/utils/other'

export default defineNuxtRouteMiddleware(async (to) => {
  // skip middleware on server and for start page, as this redirects to login
  if (process.server) {
    return
  }

  const { status, data: session, signIn } = useAuth()

  // not logged in, do not redirect as it affects keycloak redirect url
  if (status.value === 'authenticated') {
    // guest mode routes are only allowed when not logged in
    const isOnGuestOnlyRoute = guestModeRoutes.some(route => to.path.startsWith(route))
    if (isOnGuestOnlyRoute) {
      return navigateTo('/')
    }

    // @ts-ignore
    const mainRole = getMainRole(session?.value?.user?.roles?.['x-hasura-allowed-roles'])
    if (mainRole === 'client') {
      const isOnAdminOnlyRoute = adminOnlyRoutes.some(route => to.path.startsWith(route))
      const isOnSpOnlyRoute = spOnlyRoutes.some(route => to.path.startsWith(route))

      if (isOnAdminOnlyRoute || isOnSpOnlyRoute) {
        return navigateTo('/')
      }
    } else if (mainRole === 'serviceprovider') {
      const isOnAdminOnlyRoute = adminOnlyRoutes.some(route => to.path.startsWith(route))

      if (isOnAdminOnlyRoute) {
        return navigateTo('/')
      }
    }
  } else {
    const isOnGuestOnlyRoute = guestModeRoutes.some(route => to.path.startsWith(route))
    if (!isOnGuestOnlyRoute) {
      await signIn('keycloak')
    }
  }
})

const adminOnlyRoutes = ['/admin']
const spOnlyRoutes = ['/provider']
const guestModeRoutes = ['/register', '/meta/quickcheck/installer'] // routes that are only accessible when not logged in
