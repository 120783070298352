<template>
  <SharedErrorView
    :error="error"
    :on-click-method="handleError"
  />
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

defineProps({
  error: Object as () => NuxtError
})

const handleError = () => clearError({ redirect: '/' })
</script>
