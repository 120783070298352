import validate from "/usr/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import next_45usage_45global from "/usr/app/middleware/next-usage.global.ts";
import redirect_45general_45routes_45global from "/usr/app/middleware/redirect-general-routes.global.ts";
import route_45protection_45global from "/usr/app/middleware/route-protection.global.ts";
import manifest_45route_45rule from "/usr/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  next_45usage_45global,
  redirect_45general_45routes_45global,
  route_45protection_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/usr/app/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}