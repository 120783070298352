import revive_payload_client_4sVQNw7RlN from "/usr/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AOuQ1DYzjk from "/usr/app/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_KlVwwuJRCL from "/usr/app/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import _1_logger_slWUeZnfZY from "/usr/app/plugins/1.logger.ts";
import _2_event_V5w02tVVed from "/usr/app/plugins/2.event.ts";
import _3_cookieconsent_client_fhaJbrWyKD from "/usr/app/plugins/3.cookieconsent.client.ts";
import _4_sentry_client_h315b0nLbU from "/usr/app/plugins/4.sentry.client.ts";
import api_GFfDXud5Cr from "/usr/app/plugins/api.ts";
import dayjs_pGiXRjcsJO from "/usr/app/plugins/dayjs.ts";
import graphql_4fQqdKCzIn from "/usr/app/plugins/graphql.ts";
import toast_ysMjUcU7eJ from "/usr/app/plugins/toast.ts";
import vuedraggable_client_1wiR6rReiY from "/usr/app/plugins/vuedraggable.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_AOuQ1DYzjk,
  plugin_KlVwwuJRCL,
  _1_logger_slWUeZnfZY,
  _2_event_V5w02tVVed,
  _3_cookieconsent_client_fhaJbrWyKD,
  _4_sentry_client_h315b0nLbU,
  api_GFfDXud5Cr,
  dayjs_pGiXRjcsJO,
  graphql_4fQqdKCzIn,
  toast_ysMjUcU7eJ,
  vuedraggable_client_1wiR6rReiY
]